<template>
  <div class="cloth-root" @click="toggleSidebar"/>
</template>

<script>
export default {
  name: "SideBarCloth",
  methods: {
    toggleSidebar() {
      this.$store.commit("toggleSidebar")
    }
  }
}
</script>

<style scoped>
.cloth-root {
  position: fixed;
  top: 60px;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 990;
  background-color: rgba(24, 24, 33, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: .2s;
}
.cloth-root.active {
  visibility: visible;
  opacity: 1;
  left: 230px;
}
</style>
import store from "@/store";

export function handleErrorResponse(err) {
    const unnamedMessage = "Произошла внутренняя ошибка сервера. Мы уже работам над проблемой"
    const mapping = {
        403: "У вас нет прав для совершения этой операции",
        500: unnamedMessage,
        404: "Запрашиваемый ресурс не найден"
    }
    if (!err.response) {
        store.commit('addToast', {text: unnamedMessage})
        return
    }
    let message = mapping[err.response.status]
    if (!message) {
        message = 'Произошла неизвестная ошибка'
    }
    store.commit('addToast', {text: message})
}

export function formatCurrency(value, empty = "0") {
    if (typeof value !== "undefined") {
        return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(
            value,
        )
    }
    return empty
}

export function cleanObject(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );
}

export function responseExtractFilename(response) {
    const contentDisposition = response.headers['content-disposition'];
    const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
    return fileNameMatch[1]
}
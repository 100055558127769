<template>
  <div class="report-table-component">
    <div class="report-table">
      <div class="header d-flex align-items-center mb-2">
        <p class="me-2 fs-6 fw-bold mb-0">Табели</p>
        <slot></slot>
        <div class="d-flex align-items-center" v-if="!isView">
          <r-button size="small" v-if="!isAllChecked" @click="checkAll" class="ms-2 mb-0" text="Выбрать все"/>
          <r-button size="small" design="grey" v-if="checkedReports.length" @click="unCheckAll" class="ms-2 mb-0" text="Убрать выделение"/>
        </div>
      </div>
      <div class="r-scroll report-table__wrapper" v-if="reportList.length">
        <div class="pe-3 table-responsive">
          <table class="table mb-0">
            <thead>
            <tr>
              <th class="row-head ps-3">#</th>
              <th class="row-head ps-3">Дата</th>
              <th class="row-head">Пользователь</th>
              <th class="row-head">Описание</th>
              <th class="row-head">Время</th>
            </tr>
            </thead>
            <tbody>
            <tr @click.stop.prevent="onCheck(report.id)"
                :class="['row-hover', {'row-checked': isChecked(report.id)}]"
                v-for="(report, index) in reportList"
                class="default-row">
              <td><span class="ms-3 text-muted">{{ index + 1 }}</span></td>
              <td class="row-cell ps-3">{{ report.date_display }}</td>
              <td class="row-cell">
                <r-user-item size="small" :user="report.creator"/>
              </td>
              <td class="row-cell">{{ report.description }}</td>
              <td class="row-cell lg">{{ reportText(report) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <p class="text-muted mt-3 mb-0">Нет заполненных табелей</p>
      </div>
    </div>
    <div v-if="!isView && checkedReports.length" class="d-flex mt-2">
      <r-button text="Удалить"
                design="danger"
                @click="onDeleteChecked"
                v-if="checkedReports.length"/>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import RUserItem from "@/application/UIKit/RUserItem";
import {APIManager} from "@/common/helpers/api/manager";
import RButton from "@/application/UIKit/RButton.vue";

export default {
  name: "ReportTable",
  components: {RButton, RUserItem, FontAwesomeIcon},
  data() {
    return {
      checkedReports: []
    }
  },
  props: {
    reportList: Array,
    taskId: String,
    isView: Boolean
  },
  methods: {
    unCheckAll() {
      this.checkedReports = []
    },
    checkAll() {
      for (let rep of this.reportList) {
        if (!this.isChecked(rep.id) && rep.creator.id === this.$store.state.user.id) {
          this.checkedReports.push(rep.id)
        }
      }
    },
    isChecked(reportId) {
      return this.checkedReports.indexOf(reportId) > -1
    },
    onDeleteChecked() {
      APIManager.reportBulkDelete(this.checkedReports).then(res => {
        this.checkedReports = []
        this.$emit('bulkDelete', res)
      })
    },
    onCheck(reportId) {
      if (this.isView) {
        return;
      }
      const report = this.reportList.find(rep => rep.id === reportId)
      if (report.creator.id !== this.$store.state.user.id) {
        this.$store.commit('addToast', {text: "Вы можете управлять только своими табелями"})
        return
      }
      const index = this.checkedReports.indexOf(reportId)
      if (index > -1) {
        this.checkedReports.splice(index, 1)
      } else {
        this.checkedReports.push(reportId)
      }
    },
    reportText(report) {
      let text = ''
      if (report.time_hours) {
        text += `${report.time_hours}ч.`
      }
      if (report.time_minutes) {
        if (text.length) {
          text += ' '
        }
        text += `${report.time_minutes}м.`
      }
      return text
    }
  },
  computed: {
    isAllChecked() {
      return this.checkedReports.length === this.reportList.length
    }
  }
}
</script>

<style scoped>
.row-head {
  font-size: 14px;
  font-weight: bold;
  color: #1e2125;
}

.action-link {
  font-size: 14px;
}

.action-link:hover {
  cursor: pointer;
  color: #353535;
}

.row-hover:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.row-hover.row-checked {
  background-color: #eef1ff;
}

.row-cell {
  font-size: 14px;
}

.row-cell.lg {
  font-size: 15px;
}

.report-table__wrapper {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
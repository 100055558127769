<template>
  <form>
    <div class="window-header mb-3">
      <h3>Создание нового проекта</h3>
    </div>
    <div class="form-group">
      <label for="id_board_name" class="form-label">Название проекта</label>
      <input @keydown.enter.prevent="createBoard"
             @change="validateLength"
             @focusout="validateLength"
             :class="{'is-invalid': nameInvalid}"
             autocomplete="off"
             placeholder="Введите название проекта"
             v-model="boardName"
             class="form-control"
             id="id_board_name" type="text">
    </div>
    <div class="preset-list__wrapper mt-2">
      <p class="text-muted small mb-1 mt-3">Шаблон (опционально)</p>
      <div class="preset-list row mx-0">
          <board-preset-item :is-chosen="presetId === preset.id"
                             class="col"
                             @select="onPresetSelect"
                             @unselect="onPresetCancel"
                             :preset="preset"
                             v-for="preset in presetList"></board-preset-item>
      </div>
    </div>
    <div class="buttons mt-3">
      <button @click="createBoard" type="button" class="btn btn-primary">Создать</button>
      <button @click="closeModal" type="button" class="btn btn-danger ms-3">Отменить</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import {APIManager} from "@/common/helpers/api/manager";
import BoardPresetItem from "@/application/components/ViewComponents/BoardPresetComponents/BoardPresetItem";

export default {
  name: "CreateBoardWindow",
  components: {BoardPresetItem},
  data() {
    return {
      boardName: '',
      presetList: [],
      presetId: null,
      nameInvalid: false
    }
  },
  methods: {
    validateLength() {
      this.nameInvalid = !this.boardName.length;
    },
    onPresetSelect(preset) {
      if (!this.boardName) {
        this.boardName = preset.name
      }
      this.presetId = preset.id
      this.validateLength()
    },
    onPresetCancel(preset) {
      this.boardName = ''
      this.presetId = null
    },
    closeModal() {
      this.$emit('modalClose')
    },
    createBoard() {
      this.validateLength()
      if (this.nameInvalid) {
        return
      }
      const data = {
        title: this.boardName
      }
      if (this.presetId) {
        data.preset_id = this.presetId
      }
      APIManager.create({model: 'board', data: data}).then(res => {
        this.$store.dispatch("refreshBoardList")
        this.closeModal()
        this.$router.push(`/board/${res.id}`)
      })
    },
  },
  computed: {
    presetList() {
      return this.$store.getters.BOARD_TEMPLATES
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="comment-item mb-3 position-relative"
       :class="{'comment-item-delete': canDeleteComment}">
    <div class="comment-meta d-flex align-items-center">
      <div class="comment-avatar me-3">
        <r-user-item :user="comment.creator"/>
      </div>
      <p class="m-0 text-secondary small">{{ comment.created_at }}</p>
      <div v-if="canDeleteComment" @click="deleteComment" class="remove-btn ms-auto">
        <r-button size="small" :icon-only="true" design="danger" icon="trash"/>
      </div>
    </div>
    <div class="comment-text mt-1" v-html="commentText"/>
  </div>
</template>

<script>
import RUserItem from "@/application/UIKit/RUserItem";
import Button from 'primevue/button'
import RButton from "@/application/UIKit/RButton";
export default {
  name: "TaskCommentItem",
  components: {RButton, RUserItem, Button},
  props: {
    comment: {
      type: Object,
      required: true,
      default: {}
    }
  },
  methods: {
    deleteComment() {
      if (this.canDeleteComment) {
        this.$emit('deleteComment', this.comment.id)
      }
    },
  },
  computed: {
    canDeleteComment() {
      return this.comment.creator.username === this.$store.state.user.username
    },
    commentText() {
      if (this.comment && this.comment.text) {
        return this.comment.text.replace(/\bhttps?:\/\/\S+/gi, '<a class="comment-text-link" href="$&" target="_blank" rel="nofollow">$&</a>')
      }
      return ''
    }
  }
}
</script>

<style scoped>
.comment-text {
  word-break: break-all;
  font-size: 14px;
  color: #2f2f2f;
}

</style>
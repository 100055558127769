<template>
  <div class="login">
    <div class="wrapper">
      <div class="container">
        <div class="login-section">
          <div class="login__inner">
            <div class="login-block">
              <div class="block__inner">
                <div class="logotype d-flex justify-content-center mb-3"><img alt="Логотип" src="/logo.webp"></div>
                <p class="welcome-text">Вход в систему</p>
                <p v-if="!!errorMessage" class="text-danger text-center">{{ errorMessage }}</p>
                <div class="login-elements">
                  <form @submit.prevent="login" id="login-form" method="post">
                    <div class="mb-3">
                      <label for="id_email">Email</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-user"/>
                        <InputText v-model="email"
                                   name="email"
                                   id="id_email"
                                   placeholder="Email"
                                   autocomplete="email"
                                   class="w-100 ps-5"/>
                      </span>
                    </div>
                    <div class="mb-3">
                      <label for="id_password">Пароль</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-lock"/>
                      <InputText v-model="password"
                                 type="password"
                                 name="password"
                                 id="id_password"
                                 placeholder="Пароль"
                                 autocomplete="password"
                                 class="w-100 ps-5"/>
                      </span>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      <div v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status"></div>
                      Войти
                    </button>
                  </form>
                  <div class="mt-3 d-flex justify-content-between">
                    <p class="text-muted my-0 me-3 small">Нет аккаунта?</p>
                    <router-link class="small text-decoration-none" to="/auth/register">Зарегистрироваться</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'

export default {
  name: "LoginView",
  components: {InputText},

  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      isLoading: false,
    }
  },
  methods: {
    login() {
      this.isLoading = true
      const email = this.email;
      const password = this.password;
      this.$store.dispatch('login', {email: email, password: password})
          .then(() => {
            this.$router.push('/')
          })
          .catch(error => {
            this.isLoading = false
            if (error.response && error.response.status === 400) {
              this.errorMessage = 'Введенные данные не верны'
            }
          })
    }
  }
}
</script>

<style scoped>
.logotype > img {
  height: 65px;
}

label {
  color: #7c8591;
}

.container {
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-section {
  padding: 30px;
  width: 500px;
  background: white;
  border-radius: 7px;
  box-shadow: 5px 5px 25px #dddddd;
}

.login__inner {
  padding: 10px 25px 25px;
}

input:focus {
  border-bottom: 1px #4ac1f7 solid;
}

.welcome-text {
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #4e4e4e;
}

.btn {
  display: block;
  width: 100%;
}

@media (max-width: 524px) {
  .login-section {
    width: 100%;
    max-height: unset;
  }

  .login__inner {
    padding: 0;
  }

  .welcome-text {
    font-size: 1.2em;
    margin: 20px 0;
  }

  .login-section {
    padding: 10px;
  }
}
</style>
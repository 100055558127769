<template>
  <div class="r-avatar">
    <img :alt="rAlt" :class="imgClassName" class="r-avatar-image" :src="src">
    <span :class="textClassName" class="r-avatar-text" v-if="text">{{ text }}</span>
  </div>
</template>
<script>
export default {
  name: "RAvatar",
  props: {
    color: {},
    design: String,
    size: {
      type: String,
    },
    src: {
      type: String,
      required: true,
      default: ''
    },
    alt: {
      type: String,
      required: true,
      default: ''
    },
    text: {
      type: String,
    }
  },
  computed: {
    rAlt() {
      return this.alt ? this.alt : "Изображение"
    },
    imgClassName() {
      const mapping = {
        default: "h-30",
        small: "h-20",
        large: "h-40",
      }
      let className = mapping["default"]
      if (this.size) {
        className = mapping[this.size]
      }
      if (this.design) {
        className += ' ' + this.design
      }
      return className
    },
    textClassName() {
      const mapping = {
        default: "ms-2 fs-default",
        small: "ms-1 fs-small",
        large: "ms-2 fs-lg",
      }
      let className = mapping["default"]
      if (this.size) {
        className = mapping[this.size]
      }
      if (this.color === 'white') {
        className += ' ' + 'text-white'
      }
      return className
    }
  }
}
</script>

<style scoped>
.r-avatar {
  display: flex;
  align-items: center;
}

.r-avatar > span {
  display: block;
}

.r-avatar-image {
  border-radius: 50%;
}

.r-avatar-image.square {
  border-radius: 5px;
}

.h-40 {
  height: 40px;
  width: 40px;
}

.h-30 {
  height: 30px;
  width: 30px;
}

.h-20 {
  height: 20px;
  width: 20px;
}

.fs-small {
  font-size: 14px;
}

.fs-default {
  font-size: 15px;
}

.fs-lg {
  font-size: 18px;
}
</style>
<template>
  <div class="comments-section">
    <div class="d-flex align-items-center mb-2">
      <p class="me-2 mb-0 fs-6 fw-bold text-muted">Комментарии</p>
      <r-button icon-only
                @click="enableCommentCreate"
                v-if="!onCommentCreate"
                icon="pen"
                design="grey"
                size="small"/>
      <r-button icon-only
                @click="onCommentCreate=false"
                v-if="onCommentCreate"
                icon="minus"
                design="grey"
                size="small"/>
    </div>
    <div v-if="comments.length" class="comments">
      <task-comment-item @deleteComment="deleteComment" :comment="comment" :key="comment.id" v-for="comment in comments"/>
    </div>
    <div v-else>
      <p class="mb-0 text-muted text-pre-line">Начните обсуждение</p>
    </div>
    <comment-create-form class="mt-2"
                         ref="commentForm"
                         v-show="onCommentCreate"
                         @commentCreate="createComment"
                         :mention-items="mentionItems"/>
  </div>
</template>

<script>
import TaskCommentItem from "@/application/components/ViewComponents/TaskComponents/TaskCommentItem";
import CommentCreateForm from "@/application/components/ViewComponents/TaskComponents/CommentCreateForm";
import RButton from "@/application/UIKit/RButton.vue";

export default {
  name: "TaskComments",
  components: {RButton, CommentCreateForm, TaskCommentItem},
  props: {
    boardMembers: {
      type: Array,
      required: true,
      default: []
    },
    comments: {
      type: Array,
      required: true,
      default: []
    }
  },
  data() {
    return {
      onCommentCreate: false
    }
  },
  methods: {
    enableCommentCreate() {
      this.onCommentCreate = true
      this.$nextTick(() => {
        const textarea = this.$refs.commentForm.$el.querySelector('textarea')
        textarea.focus()
        setTimeout(() => {
          textarea.scrollIntoView({behavior: "smooth"})
        }, 300)
      })
    },
    createComment(text) {
      this.$emit('commentCreate', text)
    },
    deleteComment(id) {
      this.$emit('deleteComment', id)
    }
  },
  computed: {
    mentionItems() {
      const mentionItems = []
      if (this.boardMembers) {
        this.boardMembers.forEach((e) => {
          if (e.username !== this.$store.state.user.username) {
            mentionItems.push({
              label: e.username,
              value: e.username,
              searchText: e.username
            })
          }
        })
      }
      return mentionItems
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="task-footer p-1 mt-auto">
    <div class="d-flex">
      <div>
        <router-link :to="{name: 'boardDetail', params: {id: this.$route.params.id}}" class="btn btn-primary btn-sm">
          Закрыть
        </router-link>
      </div>
      <div class="d-flex">
        <button @click="onDelete" class="btn btn-danger btn-sm ms-2">
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskFooter",
  methods: {
    onDelete() {
      this.$emit('toggleTaskDelete')
    }
  }
}
</script>

<style scoped>
.task-footer {
  align-self: end;
  vertical-align: bottom;
}
</style>
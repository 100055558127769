<template>
  <div>
    <div v-if="permissionList.length">
      <table class="table table-striped table-borderless table-hover">
        <thead class="position-sticky">
        <tr>
          <td></td>
          <td class="permission-head" v-for="permission in permissionList">
            <div class="d-flex align-items-center">
              <div class="ms-auto me-3">
                <r-user-item size="small" :user="permission.user"/>
              </div>
            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="field in fieldList">
          <td class="small default-table-label">
            <div>{{ field.label }}</div>
            <span v-if="field.help_text" class="small text-muted">{{ field.help_text }}</span>
          </td>
          <td class="default-table-cell" v-for="perm in permissionList">
            <div class="d-flex">
              <div class="ms-auto me-3">
                <input @change="onPermChange(field.name, perm[field.name], perm.id)"
                       v-model="perm[field.name]"
                       :name="field.name"
                       class="form-check-input"
                       type="checkbox">
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="mt-1">
      <p class="text-muted">Нет участников</p>
    </div>
  </div>
</template>

<script>
import DropdownSelect from "@/application/components/UIComponents/DropdownSelect";
import {APIManager} from "@/common/helpers/api/manager";
import RAvatar from "@/application/UIKit/RAvatar";
import RUserItem from "@/application/UIKit/RUserItem";

export default {
  name: "PermissionList",
  components: {RUserItem, RAvatar, DropdownSelect},
  data() {
    return {
      fields: [
        {name: 'edit_board', label: 'Редактирование проекта', help_text: 'В т.ч. управление участниками'},
        {name: 'delete_tasks', label: 'Удаление задач'},
        {name: 'delete_stages', label: 'Удаление этапов'},
      ],
      notificationFields: [
        {name: 'receive_notifications', label: 'Получать уведомления'}
      ]
    }
  },
  methods: {
    onPermChange(field, value, id) {
      const data = {}
      data[field] = value
      this.updatePermission(data, id)
    },
    itemSelected(data, id) {
      this.updatePermission(data, id)
    },
    updatePermission(data, id) {
      APIManager.update({model: 'permission', id: id, data: data}).then(res => {
        this.$emit('permissionChange', res)
      })
    }
  },
  props: {
    permissionList: {
      type: Array,
      required: true
    },
    board: Object,
    mode: {
      type: String,
    },
    checkPermissions: Boolean
  },
  computed: {
    fieldList() {
      return this.mode === 'notifications' ? this.notificationFields : this.fields
    }
  }
}
</script>

<style scoped>

.default-table-cell {
  min-width: 150px;
}

.permission-head {
  font-size: 14px;
}

.avatar-img {
  height: 20px;
  width: 20px;
}
</style>
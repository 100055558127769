<template>
  <div>
    <form @submit.prevent="createComment" class="form-inside r-scroll">
      <Mentionable :items="mentionItems" :keys="['@']">
        <template #no-result>
          <div class="v-popper_no_result__inner">
            Нет результатов
          </div>
        </template>
        <textarea v-model="commentText"
                  style="height: 100px;"
                  class="comment-textarea"
                  @input="onInput"
                  @keydown.ctrl.enter="createComment"
                  spellcheck="false"
                  placeholder="Введите текст комментария"></textarea>
      </Mentionable>
      <div class="comment-doc">
        <div class="form-inside-bar d-flex align-items-center">
          <r-button rounded="1" text="Отправить"/>
          <p class="m-0 text-muted very-small ms-2">Отправить ctrl+enter</p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {Mentionable} from "vue-mention";
import RButton from "@/application/UIKit/RButton";

export default {
  name: "CommentCreateForm",
  components: {RButton, Mentionable},
  data() {
    return {
      commentText: ''
    }
  },
  props: {
    mentionItems: {
      type: Array,
      default: [],
    }
  },
  methods: {
    onInput(event) {
      const element = event.target
      if (element.scrollHeight > 30) {
        element.style.height = (element.scrollHeight) + "px";
      }
    },
    createComment() {
      if (!this.commentText.length) {
        return
      }
      this.$emit('commentCreate', this.commentText)
      this.commentText = ''
    }
  }
}
</script>
<style>
.comment-doc {
  min-height: 35px;
}
</style>
<template>
  <div class="pseudo-checkbox">
    <div class="input-group">
      <input @keydown.enter="createCheckbox" v-model="itemText" class="form-control form-control-sm very-small" placeholder="Введите текст">
      <button @click="createCheckbox" class="btn btn-primary btn-sm">
        <font-awesome-icon class="mt-1" icon="fas fa-check"/>
      </button>
    </div>
  </div>
</template>

<script>
import {APIManager} from "@/common/helpers/api/manager";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "PseudoCheckbox",
  components: {FontAwesomeIcon},
  props: ['taskId'],
  data() {
    return {
      itemText: ''
    }
  },
  methods: {
    createCheckbox() {
      if (!this.itemText.length) {
        return
      }
      APIManager.create({
        model: 'checkbox', data: {
          text: this.itemText,
          task_id: this.taskId,
          is_done: false
        }
      }).then(res => {
        this.itemText = ''
        this.$emit('create', res)
      })
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <r-avatar :color="color" :design="design" :size="size" :text="user.username" :src="user.avatar" :alt="user.username"/>
</template>

<script>
import RAvatar from "@/application/UIKit/RAvatar";
export default {
  name: "RUserItem",
  components: {RAvatar},
  props: {
    user: {
      type: Object,
      required: true,
      default: {
        username: '',
        avatar: ''
      }
    },
    size: String,
    design: String,
    color: String
  }
}
</script>

<style scoped>

</style>
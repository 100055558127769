<template>
  <div>
    <vue-title :title="boardPageTitle"></vue-title>
    <div class="kanban">
      <div class="row p-1">
        <div class="col-8">
          <input @click="setBoardName=true" class="d-block w-100 form-control-c form-control-c-lg "
                 spellcheck="false"
                 title=""
                 type="text"
                 @focusout="updateBoardName(boardData)"
                 @change="updateBoardName(boardData)"
                 ref="boardTitle"
                 @keydown.enter="updateBoardName(boardData)"
                 v-model="boardData.title" style="z-index: 100;"
                 :class="{'bordered-name': setBoardName===true}">
        </div>
        <div class="col-4 toolbar d-flex align-items-center justify-content-end">
          <div class="ms-2">
            <ToggleButton onLabel="" offLabel=""
                          title="В архиве"
                          v-model="showArchived"
                          onIcon="pi pi-box"
                          offIcon="pi pi-box"></ToggleButton>
          </div>
          <div class="ms-2">
            <r-button class="outlined" @click="toggleBoardMenu" icon="ellipsis" size="lg" icon-only design="white"/>
            <Menu class="position-absolute" ref="menu" id="overlay_menu" :model="boardMenuItems" :popup="true"/>
          </div>
        </div>
      </div>
      <div class="stage-list__wrapper r-scroll">
        <div class="stage-list">
          <div class="stage-card__wrapper stage_list-item"
               v-for="stage in boardData.stages" :key="stage.id">
            <stage-card @taskCreated="handleTaskCreate"
                        @taskUpdate="handleTaskUpdate"
                        @taskMove="handleTaskMove"
                        @swap="handleStageSwap"
                        @taskDrop="handleTaskDrop"
                        @taskDelete="handleTaskDelete"
                        @bulkArchive="handleBulkArchive"
                        :show-archived="showArchived"
                        :stage-count="boardData.stages.length"
                        :key="stage.id"
                        @stageDelete="handleStageDelete"
                        v-bind:stage="stage"/>
          </div>
          <create-new-stage :key="Date.now()"
                            @additionFocus="handleAdditionFocus"
                            @createStage="handleStageCreate"
                            :board_id="boardData.id ? boardData.id : ''"
                            :stages="boardData.stages"/>
        </div>
      </div>
      <transition name="popup">
        <view-modal @modalClose="handleCloseModal">
          <router-view v-bind:stageList="boardData.stages"
                       @modalClose="handleCloseModal"
                       v-bind:boardMembers="boardData.members ? boardData.members : []"></router-view>
        </view-modal>
      </transition>
    </div>
  </div>
</template>

<script>
import CreateNewStage from "@/application/components/ViewComponents/StageComponents/CreateNewStage";
import StageCard from "@/application/components/ViewComponents/StageComponents/StageCard";
import CustomModal from "@/application/components/UIComponents/CustomModal";
import ViewModal from "@/application/components/UIComponents/ViewModal";
import BoardSettings from "@/application/components/ViewComponents/BoardComponents/BoardSettings";
import VueTitle from "@/application/components/MiscComponents/VueTitle";
import {APIManager} from "@/common/helpers/api/manager";
import DestroyConfirmationModal from "@/application/components/UIComponents/DestroyConfirmationModal";
import ToggleButton from 'primevue/togglebutton'
import RButton from "@/application/UIKit/RButton";
import Menu from "primevue/menu"
import {dashboard} from "@/common/helpers/api/dashboard";

export default {
  name: "BoardView",
  components: {
    Menu,
    RButton,
    DestroyConfirmationModal,
    VueTitle,
    BoardSettings,
    ToggleButton,
    CustomModal,
    StageCard,
    CreateNewStage,
    ViewModal
  },
  data() {
    return {
      showArchived: new URLSearchParams(window.location.search).get('archive') === 'true' || localStorage.getItem('archive') === 'true',
      boardMenuItems: [
        {
          label: 'Настройки',
          command: () => {
            this.$router.push({name: 'boardSettings', params: {id: this.boardData.id}})
          }
        },
        {
          label: 'Удалить',
          command: () => {
            this.$router.push({name: 'boardDelete', params: {id: this.boardData.id}})
          },
        },
      ],
      showConfirmationModal: false,
      boardPageTitle: 'Проект',
      boardData: {
        creator: {},
        permissions: []
      },
      stageMenuActive: false,
      activeMenus: {},
      newStageName: '',
      setBoardName: false,
      boardCreatedName: '',
      boardMenuEnabled: false,

      highlightedStage: false,
      isOnDrag: false,

      isLoading: true
    }
  },
  methods: {
    toggleBoardMenu(event) {
      this.$refs.menu.toggle(event)
    },
    handleStageSwap(data) {
      if (data.order === data.oldOrder) {
        return
      }
      this.boardData.stages[data.oldOrder].order = data.order
      this.swapStages(this.boardData.stages, data.order, data.oldOrder)
    },
    swapStages(stages, index1, index2) {
      if (index1 < 0 || index2 < 0 || index1 >= stages.length || index2 >= stages.length) {
        throw new Error('Invalid indices');
      }
      [stages[index1], stages[index2]] = [stages[index2], stages[index1]];
    },
    handleTaskMove(task) {
      const stage = this.boardData.stages.find(stage => stage.id === task.stage)
      if (stage) {
        const foundTask = stage.tasks.find(t => t.id === task.id)
        if (foundTask) {
          Object.assign(foundTask, task)
        }
      }
    },
    handleTaskDrop(tasks, stage_id) {
      const stage = this.boardData.stages.find(stage => stage.id === stage_id)
      if (stage) {
        stage.tasks = tasks
      }
    },
    handleBulkArchive(data, stage_id) {
      const stage = this.boardData.stages.find(s => s.id === stage_id)
      if (stage) {
        stage.tasks = data
      }
    },
    handleTaskDelete(task) {
      const stage = this.boardData.stages.find(stage => stage.id === task.stage)
      stage.tasks = stage.tasks.filter(t => t.id !== task.id)
    },
    handleTaskCreate(tasks) {
      const stage = this.boardData.stages.find(stage => stage.id === tasks.stage)
      stage.tasks.unshift(tasks)
    },
    handleTaskUpdate(task) {
      const stage = this.boardData.stages.find(stage => stage.id === task.stage)
      if (stage) {
        stage.tasks.forEach(t => {
          if (t.id === task.id) {
            Object.assign(t, task)
          }
        })
      }
    },
    handleCloseModal() {
      let url = {name: 'boardDetail', id: this.$route.params.id, query: null}
      this.$router.push(url)
    },
    handleAdditionFocus(el) {
      this.$nextTick(() => el.focus())
    },
    handleStageCreate(stage) {
      this.boardData.stages[this.boardData.stages.length] = stage;
      this.setDefaultState();
    },
    handleStageDelete(id) {
      this.setDefaultState()
      this.boardData.stages = this.boardData.stages.filter(stage => stage.id !== id)
    },
    async getBoardData() {
      let params = null
      if (this.showArchived === true) {
        params = '?archive=true'
      }
      APIManager.detail({model: 'board', id: this.$route.params.id}, params).then(response => {
        this.boardData = response
        this.$store.commit('setCurrentPermissions', response.permissions)
        this.setDefaultState()
      }).catch(err => console.log(err))
    },
    setDefaultState() {
      this.newStageName = '';
      this.showAdditionStage = '';
      this.setBoardName = '';
      this.stageMenuActive = '';
      this.isLoading = false;
    },
    projectListCompareCallback(e, res) {
      if (e.id === res.id) {
        e.title = res.title
      }
    },
    sendUpdateBoardRequest(value) {
      const title = value.title || value
      const data = {title: title}
      APIManager.update({model: 'board', id: this.$route.params.id, data: data}).then(res => {
        this.boardData.title = res.title
        this.$store.state.dashboard.project_list.forEach((e, i) => this.projectListCompareCallback(e, res))
        this.$store.state.dashboard.member_project_list.forEach((e, i) => this.projectListCompareCallback(e, res))
      })
    },
    updateBoardName(board) {
      this.setDefaultState()
      this.$nextTick(() => this.$refs.boardTitle.blur())
      if (board.title === this.boardCreatedName) {
        return
      }
      this.sendUpdateBoardRequest(board)
    },
  },
  async created() {
    await this.getBoardData()
  },
  watch: {
    $route(to, fr) {
      if (to && to.name === 'boardDetail') {
        this.$nextTick(() => {
          this.getBoardData()
        })
      }
    },
    setBoardName() {
      this.boardCreatedName = this.boardData.title;
    },
    boardData(newData, oldData) {
      if (newData) {
        this.boardPageTitle = newData.title
      }
    },
    showArchived() {
      let url
      if (this.showArchived) {
        localStorage.setItem('archive', 'true')
        url = '?' + 'archive=' + 'true'
      } else {
        localStorage.removeItem('archive')
        url = '?' + 'archive=' + 'false'
      }
      history.pushState('', '', url)
      this.getBoardData()
    }
  },
}
</script>

<style scoped>
.menu-link__wrapper {
  color: #191c1f;
  text-decoration: none;
  font-size: .875em;
}

.edit-board-btn {
  padding: 8px 15px;
}

.menu-disabled {
  opacity: 0;
  visibility: hidden;
}

.menuitem-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
  min-width: 30px;
}

.board-menu {
  margin: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  font-size: 14px;
  color: #3c3c3c;
  padding: 5px 0;
  min-width: 170px;
}

.board-menuitem {
  padding: 5px 15px;

}

.board-menuitem:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.board-menuitem > div > div {
  font-size: 13px;
  color: #414141;
}

.stage-list__wrapper {
  height: calc(100svh - 140px);
  overflow-x: auto;
}

.bordered-name {
  background-color: white;
  outline: 2px solid #dbdbdb;
}

.stage-list {
  width: max-content;
}

.stage-card__wrapper {
  vertical-align: top;
  width: 320px;
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;
  height: max-content;
  border-radius: 5px;
}

.stage_list-item {
  display: inline-block;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .form-control-c-lg {
    width: 100% !important;
  }
}

</style>
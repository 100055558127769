<template>
    <div class="board-member-suggestion">
        <div class="mb-3">
            <label for="id_suggest" class="form-label">Пригласить участника</label>
            <div class="input-group">
                <input id="id_suggest"
                       autocomplete="member_suggestion"
                       v-model="newMemberEmail"
                       :disabled="disabled"
                       @keydown.enter="inviteMember"
                       placeholder="Введите email пользователя"
                       class="form-control form-control-sm">
                <button :disabled="disabled" @click="inviteMember" class="btn btn-primary btn-sm">
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {InviteManager} from "@/common/helpers/api/invite_members";

  export default {
    name: "BoardMemberSuggestion",
    data() {
      return {
        newMemberEmail: ''
      }
    },
    props: ['board_id', 'disabled'],
    methods: {
      inviteMember() {
        if (this.disabled) {
          return
        }
        InviteManager.invite(this.board_id, this.newMemberEmail).then(res => {
          this.newMemberEmail = ''
          this.$emit('memberInvite', res)
        }).catch(err => {
          this.newMemberEmail = ''
          if (err.response && err.response.status === 400) {
            this.$store.commit('addToast', {text: err.response.data.errors.user})
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
import store from '@/store/index'
import axios from "axios";
import {handleErrorResponse} from "@/common/helpers/api/utils";

export const APIManager = {
  list: ({model}, addr = null) => {
    let url = `${store.state.apiUrl}/${model}`
    if (addr) {
      url = addr
    }
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  detail: ({model, id}, params = null) => {
    let url = `${store.state.apiUrl}/${model}/${id}`
    if (params) {
      url += params
    }
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  create: ({model, data}) => {
    const url = `${store.state.apiUrl}/${model}`
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  destroy: ({model, id}) => {
    const url = `${store.state.apiUrl}/${model}/${id}`
    return new Promise((resolve, reject) => {
      axios.delete(url).then(response => resolve(response)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  update: ({model, id, data}) => {
    const url = `${store.state.apiUrl}/${model}/${id}`
    return new Promise((resolve, reject) => {
      axios.patch(url, data).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  getPresetList() {
    const url = `${store.state.apiUrl}/board_template`
    return new Promise((resolve, reject) => {
      axios.get(url).then(res => resolve(res.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  getBoardSettingsData: ({model, id}) => {
    const url = `${store.state.apiUrl}/${model}/${id}?settings=true`
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  bulkArchive: (stageId, revert = false) => {
    let url = `${store.state.apiUrl}/stage/${stageId}/archive`
    const data = {revert: revert}
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  get_checkboxes: (taskId) => {
    const url = `${store.state.apiUrl}/task/${taskId}/checkbox`
    return new Promise((resolve, reject) => {
      axios.get(url).then(res => resolve(res.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  getBoardMeta(board_id) {
    const url = `${store.state.apiUrl}/board/meta/${board_id}`
    return new Promise((resolve, reject) => {
      axios.get(url).then(res => {
        resolve(res.data)
      }).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  reportBulkDelete(reportIds) {
    const url = `${store.state.apiUrl}/report/bulk_delete`
    const data = {
      report_ids: reportIds
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(res => {
        resolve([...reportIds])
      }).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },
  getReportMeta() {
    return new Promise((resolve, reject) => {
      const url = `${store.state.apiUrl}/report/meta`
      axios.get(url).then(res => resolve(res.data)).catch(err => handleErrorResponse(err))
    })
  },
  getReportList(data) {
    let url = `${store.state.apiUrl}/report`
    return new Promise((resolve, reject) => {
      axios.get(url, {params: data}).then(response => resolve(response.data)).catch(err => {
        handleErrorResponse(err)
        reject(err)
      })
    })
  },

  async reportDownload(data) {
    let url = `${store.state.apiUrl}/report/export`
    try {
      return axios.get(url, {params: data, responseType: "blob"})
    } catch (e) {
      handleErrorResponse(e)
    }
  }
}
import {createStore} from "vuex";
import axios from "axios";
import {environments} from "@/common/conf";
import {APIManager} from "@/common/helpers/api/manager";
import {dashboard} from "@/common/helpers/api/dashboard";


export default createStore({
    state: {
        sidebarActive: false,
        apiUrl: environments.debug ? "http://localhost:8000/api/v1" : "https://api.taskease.ru/api/v1",
        hostUrl: environments.debug ? "http://localhost:8000" : "https://api.taskease.ru",
        token: localStorage.getItem("token") || "",
        prevRoute: null,
        route: null,
        user: {},
        boardList: [],
        toastList: [],
        dashboardLoaded: false,
        userLoaded: false,
        confirmationUrl: "/auth/users/activation/",
        currentPermissions: [],
        boardTemplates: [],
        dashboardUpdated: null,
        dashboard: {
            project_list: [],
            member_project_list: [],
            total_budget: {},
            total_real_budget: {},
            tasks: {},
            projects: {},
        },
    },
    getters: {
        BOARD_TEMPLATES(state) {
            return state.boardTemplates
        },
        LOADED(state) {
            return state.dashboardLoaded && state.userLoaded
        },
        SIDEBAR_ACTIVE(state) {
            return state.sidebarActive
        },
        API_URL(state) {
            return state.apiUrl
        },
        HOST_URL(state) {
            return state.hostUrl
        },
        IS_AUTH: state => {
            return !!state.token
        },
        CONFIRMATION_URL: state => {
            return state.hostUrl + state.confirmationUrl
        },
        TOAST_LIST: state => {
            return state.toastList
        },
    },
    mutations: {
        setRoute(state, route) {
            state.route = route
        },
        setPrevRoute(state, route) {
            state.prevRoute = route
        },
        setBoardTemplates(state, boardList) {
            this.state.boardTemplates = boardList
        },
        setCurrentPermissions(state, permissions) {
            state.currentPermissions = permissions
        },
        setUserIsLoaded(state) {
            state.userLoaded = true
        },
        setDashboardLoaded(state) {
            state.dashboardLoaded = true
        },
        addToast(state, toast) {
            state.toastList.unshift({
                key: Symbol(),
                ...toast
            })
        },
        removeToast(state, index) {
            state.toastList.splice(index, 1)
        },
        toggleSidebar(state) {
            state.sidebarActive = !state.sidebarActive
        },
        closeSidebar(state) {
            state.sidebarActive = false
        },
        set_user(state, user) {
            state.user = user
        },
        auth_success(state, token) {
            state.token = token
            localStorage.setItem("token", token)
            axios.defaults.headers.common["Authorization"] = `Token ${token}`
        },
        auth_error(state) {
            state.token = ""
        },
        erase_auth_data(state) {
            return new Promise((resolve, reject) => {
                state.token = ""
                state.user = {}
                state.dashboardLoaded = false
                state.userLoaded = false
                localStorage.removeItem("token")
                if (!!axios.defaults.headers.common["Authorization"]) {
                    delete axios.defaults.headers.common["Authorization"]
                }
                resolve()
            })
        },
        setDashboard(state, dashboard) {
            state.dashboard = dashboard
            state.dashboardLoaded = true
            let date = new Date()
            // let options = {weekday: 'short', month: 'short', day: 'numeric' }
            // state.dashboardUpdated = date.toLocaleString('ru-RU', options)
            state.dashboardUpdated = date
        },
        removeBoardFromList(state, boardId) {
            state.dashboard.project_list = state.dashboard.project_list.filter(item => item.id !== boardId)
            state.dashboard.member_project_list = state.dashboard.member_project_list.filter(item => item.id !== boardId)
        },
        updateBoardList(state, projects) {
            state.dashboard.project_list = projects.project_list
            state.dashboard.member_project_list = projects.member_project_list
        }
    },
    actions: {
        async refreshBoardList(context) {
            try {
                const response = await dashboard.refreshBoardList()
                context.commit("updateBoardList", response.data)
                return response.data
            } catch (e) {
                return e
            }
        },
        async getDashboard(context) {
            try {
                const response = await dashboard.getDashboardBoardList()
                context.commit("setDashboard", response.data)
                return response.data
            } catch (e) {
                return e
            }
        },
        async getBoardTemplates(context) {
            context.commit("setBoardTemplates", await APIManager.getPresetList())
        },
        get_user(context, token) {
            return new Promise((resolve, reject) => {
                const url = `${context.getters.API_URL}/me`
                axios(url, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                }).then(response => {
                    context.commit("setUserIsLoaded")
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        login(context, user) {
            return new Promise((resolve, reject) => {
                const url = `${context.getters.HOST_URL}/auth/token/login`
                axios.post(url, user).then(response => {
                    const token = response.data.auth_token
                    context.commit("auth_success", token)
                    context.dispatch("get_user", token).then(userdata => {
                        context.commit("set_user", userdata)
                        resolve()
                    }).catch(error => reject(error))
                }).catch(error => {
                    localStorage.removeItem("token")
                    context.commit("auth_error")
                    reject(error)
                })
            })

        },
        register(context, user) {
            return new Promise((resolve, reject) => {
                const url = `${context.getters.HOST_URL}/auth/users/`
                axios.post(url, user).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        logout(context) {
            return new Promise((resolve, reject) => {
                const url = `${context.getters.HOST_URL}/auth/token/logout`
                axios.post(url).then(response => {
                    delete axios.defaults.headers.common["Authorization"]
                    context.commit("erase_auth_data")
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })

        },
        resetAuth(context) {
            context.commit("erase_auth_data")
        }
    },
    modules: {}
})
import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router/router";
import store from '@/store'
import axios from "axios";

import {HelperMixin} from "@/common/mixins"
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import '@/assets/vendor/bootstrap.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-light-indigo/theme.css'
import '@/assets/styles.css'

import Tooltip from 'primevue/tooltip';
/* import specific icons */
import {
  faInfoCircle,
  faTimes,
  faInfo,
  faThList,
  faDownload,
  faExternalLink,
  faUserSecret,
  faTruckLoading,
  faBell,
  faBan,
  faComments,
  faHomeUser,
  faUser,
  faTableList,
  faCircleRight,
  faEllipsis,
  faTrashCan,
  faBoxArchive,
  faSquarePlus,
  faCirclePlus,
  faTableColumns,
  faXmark,
  faPlus,
  faArrowRightFromBracket,
  faBars,
  faPaperPlane,
  faTrash,
  faArrowRight,
  faGears,
  faGear,
  faCheck,
  faCircle,
  faFlag,
  faBox,
  faEye,
  faArrowRotateLeft,
  faArrowRotateRight,
  faList, faMinus, faClock, faPen, faSyncAlt
} from '@fortawesome/free-solid-svg-icons'
import PrimeVue from "primevue/config";


/* add icons to the library */
library.add(faThList)
library.add(faSyncAlt)
library.add(faMinus)
library.add(faClock)
library.add(faPen)
library.add(faInfo)
library.add(faInfoCircle)
library.add(faList)
library.add(faUserSecret)
library.add(faExternalLink)
library.add(faTruckLoading)
library.add(faBell)
library.add(faHomeUser)
library.add(faUser)
library.add(faTableList)
library.add(faCircleRight)
library.add(faEllipsis)
library.add(faTrashCan)
library.add(faBoxArchive)
library.add(faSquarePlus)
library.add(faCirclePlus)
library.add(faTableColumns)
library.add(faXmark)
library.add(faPlus)
library.add(faComments)
library.add(faArrowRightFromBracket)
library.add(faBars)
library.add(faPaperPlane)
library.add(faTrash)
library.add(faArrowRight)
library.add(faGear)
library.add(faCheck)
library.add(faCircle)
library.add(faFlag)
library.add(faBox)
library.add(faEye)
library.add(faArrowRotateLeft)
library.add(faArrowRotateRight)
library.add(faBan)
library.add(faDownload)

const app = createApp(App)
app.mixin(HelperMixin)

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(function (config) {
    if (!axios.defaults.headers.common['Authorization'] && !!store.state.token) {
      axios.defaults.headers.common['Authorization'] = `Token ${store.state.token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(undefined, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      store.dispatch("resetAuth").then(res => {
        router.push('/auth/login')
      })
    }
    if (err.response && err.response.status === 404 && err.config && !err.config.__isRetryRequest) {
      router.push('/not-found')
    }
    throw err;
  });
});

app
  .use(router)
  .use(store)
  .use(PrimeVue)
  .directive('tooltip', Tooltip)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')

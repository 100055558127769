<template>
  <div class="sidebar bg-b-dark"
       :class="{ 'sidebar-active': sidebarActive }">
    <ul class="main-nav-list">
      <SideBarMainMenuItem text="Главная" icon="fas fa-house-user" link="/"/>
      <SideBarMainMenuItem text="Табели" icon="fas fa-list" :link="{name: 'reportList'}"/>
      <li class="main-nav-item mt-3 nested-menu">
        <p class="small ps-3 text-white-50 pb-0 mb-1">
          <font-awesome-icon icon="fa-solid fa-table-columns" class="me-2"/>
          <span class="main-menu-divider">Проекты</span>
        </p>
        <ul v-if="projectList.length" class="nested-sidebar-menu">
          <SideBarMenuItem v-for="board in projectList"
                           @menuLoaded="onMenuLoaded"
                           @context="onContextMenu"
                           showContext
                           @leaveBoard="leaveBoard"
                           @toggleArchiveBoard="showArchive"
                           @toggleDeleteBoard="showDelete"
                           :board="board"
                           :key="board.id"/>
        </ul>
        <div class="mt-2">
          <div @click="toggleCreateBoardModal"
               class="main-nav-link">
            <span class="main-link-text item-small ps-0">Создать новый проект</span>
          </div>
        </div>
      </li>
    </ul>
    <div class="logo">
      <img src="/logo_white_100.png" class="logo-img" alt="Cloud Resolve">
    </div>
    <SideBarCloth :class="{'active': sidebarActive}"/>
  </div>
</template>

<script>
import ContextMenu from "primevue/contextmenu";
import SideBarMenuItem from "@/application/components/UIComponents/SideBarMenuItem.vue";
import {InviteManager} from "@/common/helpers/api/invite_members";
import SideBarCloth from "@/application/components/UIComponents/SideBarCloth.vue";
import SideBarMainMenuItem from "@/application/components/UIComponents/SideBarMainMenuItem.vue";

export default {
  name: "SideBar",
  components: {SideBarCloth, SideBarMenuItem, SideBarMainMenuItem, ContextMenu},
  data() {
    return {
      menuItemCallbacks: {}
    }
  },
  computed: {
    sidebarActive() {
      return this.$store.getters.SIDEBAR_ACTIVE
    },
    projectList() {
      const projectList = []
      for (let i of this.$store.state.dashboard.project_list) {
        projectList.push({id: i.id, title: i.title, creatorId: i.creator_id})
      }
      for (let i of this.$store.state.dashboard.member_project_list) {
        projectList.push({id: i.id, title: i.title, creatorId: i.creator_id})
      }
      return projectList
    },
  },
  methods: {
    onContextMenu($event, id) {
      for (const [key, value] of Object.entries(this.menuItemCallbacks)) {
        if (value) {
          value.hide($event)
        }
      }
      this.menuItemCallbacks[id].show($event)
    },
    onMenuLoaded(id, callbacks) {
      this.menuItemCallbacks[id] = callbacks
    },
    leaveBoard(boardId, userId) {
      InviteManager.exclude(boardId, userId)
      this.$store.dispatch("refreshBoardList")
    },
    showArchive(boardId) {
      this.$emit('toggleArchiveBoard', boardId)
    },
    showDelete(boardId) {
      this.$emit('toggleDeleteBoard', boardId)
    },
    toggleCreateBoardModal() {
      this.$emit('toggleCreateBoardModal')
    },
  },
  async created() {
    this.$store.dispatch("getBoardTemplates")
    await this.$store.dispatch("getDashboard")
  }
}
</script>

<style scoped>
.logo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin-top: auto;
  align-self: end;
  vertical-align: bottom;
}

.logo-img {
  height: 50px;
}

.sidebar {
  position: fixed;
  top: 60px;
  width: 230px;
  transition: .2s;
  z-index: 1000;
  height: calc(100svh - 60px);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, .3);
  left: -230px;
}

.sidebar-active {
  left: 0;
}

hr {
  color: white;
}
</style>
<template>
  <div v-if="showModal" class="custom-modal">
    <div class="modal-window position-relative">
      <div @click="closeModal" class="modal-close">
        <font-awesome-icon icon="fa-solid fa-xmark"/>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewModal",
  data() {
    return {
      showModal: false,
      force: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('modalClose', this.force)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.showModal = (newVal.meta && newVal.meta.showModal);
        this.force = (newVal.meta && newVal.meta.force);
      }
    },
  },
}
</script>

<style scoped>

.modal-close {
  padding: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #232323;
  border-radius: 50%;
  font-size: 18px;
  position: absolute;
  top: -10px;
  right: -10px;
  transition: .1s;
  font-weight: 100;
  background-color: white;
}

.modal-close:hover {
  cursor: pointer;
  background-color: var(--danger-color);
  color: white;
}

.custom-modal {
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(0, 0, 0, .6);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.modal-window {
  padding: 25px;
  background-color: white;
  border-radius: 7px;
}

@media (max-width: 850px) {
  .modal-window {
    width: 95%;
  }
}

</style>
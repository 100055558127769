<template>
  <div tabindex="1"
       @focusout="onClickOutside"
       @click.prevent.stop="onClick"
       :style="{'color': textColor}" class="r-menu__wrapper">
    <div :style="btnStyle" :class="{'btn-active': isActive}" class="r-menu-btn">
      <font-awesome-icon v-if="icon" :icon="icon"/>
    </div>
    <ul :style="menuStyle" :class="{'menu-active': isActive}" v-show="isActive" class="r-menu">
      <li v-show="!item.invisible" :key="item.text" @click.stop.prevent="onItemClick(item)" v-for="item in itemList"
          class="r-menu-item">
        <div :class="item.className" class="menu-item__inner">
          <div v-if="item.icon" class="r-menu-icon">
            <font-awesome-icon :icon="item.icon"/>
          </div>
          <span class="me-3">{{ textLookup ? item[textLookup] : item['text'] }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RMenu",
  props: {
    itemList: Array,
    textLookup: String,
    size: String,
    design: String,
    icon: String,
    color: String,
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    onClick() {
      this.isActive = !this.isActive
    },
    onClickOutside() {
      this.isActive = false
    },
    onItemClick(item) {
      this.isActive = false
      if (item.callback) {
        item.callback()
      }
      this.$emit('itemClick', item)
    }
  },
  computed: {
    btnSize() {
      let size = '32px'
      if (this.size === 'small') {
        size = '24px'
      } else if (this.size === 'default') {
        size = '32px'
      } else if (this.size === 'large') {
        size = '42px'
      }
      return {
        height: size,
        width: size
      }
    },
    btnStyle() {
      const btnStyle = {
        ...this.btnSize
      }
      return btnStyle
    },
    textColor() {
      return this.color ? this.color : '#191c1f'
    },
    menuStyle() {
      let top = '35px'
      let left = '-1px'
      if (this.size === 'small') {
        top = '24px'
      } else if (this.size === 'default') {
        top = '35px'
      } else if (this.size === 'large') {
        top = '45px'
      }
      return {
        top: top,
        left: left
      }
    }
  }
}
</script>

<style scoped>
.r-menu-icon {
  margin-right: 13px;
}

.r-menu__wrapper {
  position: relative;
}

.r-menu {
  z-index: 1000;
  position: absolute;
  width: max-content;
  list-style-type: none;
  background-color: white;
  box-sizing: content-box;
  padding: 5px 0;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
}

.r-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: box-shadow .1s linear;
}

.r-menu-btn:hover, .btn-active {
  outline: 1px solid #d9d9d9;
}

.btn-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.menu-active {
  border-top-left-radius: 0;
}

.r-menu-item {
  font-size: 14px;
  padding: 3px 15px;
}

.r-menu-item:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.menu-item__inner {
  color: #404040;
  display: flex;
  align-items: center;
}
</style>
<template>
  <div class="login">
    <div class="wrapper">
      <div class="container">
        <div class="login-section">
          <div class="login__inner">
            <div class="login-block">
              <div class="block__inner">
                <div class="logotype d-flex justify-content-center mb-3"><img alt="Логотип" src="/logo.webp"></div>
                <p class="welcome-text">Регистрация</p>
                <p v-if="!!errorMessage" class="text-danger text-center">{{ errorMessage }}</p>
                <div class="login-elements">
                  <form @submit.prevent="login" id="login" method="post">
                    <div class="mb-2">
                      <label class="form-label very-small mb-1" for="id_email">Email</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-user"/>
                        <InputText v-model="email"
                                   required="true"
                                   name="email"
                                   id="id_username"
                                   placeholder="Email"
                                   autocomplete="email"
                                   class="w-100 ps-5"/>
                      </span>
                    </div>
                    <div class="mb-2">
                      <label class="form-label very-small mb-1" for="id_username">Имя пользователя</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-users"/>
                      <input-text v-model="username"
                                  required="true"
                                  name="user-name"
                                  id="id_user"
                                  placeholder="Имя пользователя"
                                  autocomplete="user-name"
                                  class="w-100 ps-5"/>
                      </span>
                    </div>
                    <div class="mb-3">
                      <label class="form-label very-small mb-1" for="id_password">Пароль</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-lock"/>
                      <input-text v-model="password"
                                  required="true"
                                  name="password"
                                  id="id_password"
                                  type="password"
                                  placeholder="Пароль"
                                  autocomplete="password"
                                  class="w-100 ps-5"/>
                      </span>
                    </div>
                    <div class="mb-3">
                      <label class="form-label very-small mb-1" for="id_password">Повторите пароль</label>
                      <span class="p-input-icon-left w-100">
                        <i class="pi pi-lock"/>
                      <input-text v-model="re_password"
                                  required="true"
                                  name="re_password"
                                  id="id_re_password"
                                  type="password"
                                  placeholder="Повторите пароль"
                                  autocomplete="re-password"
                                  class="w-100 ps-5"/>
                      </span>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      <div v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status"></div>
                      Зарегистрироваться
                    </button>
                  </form>
                  <div class="mt-3 d-flex justify-content-between">
                    <p class="text-muted my-0 me-3 small">Уже есть аккаунт?</p>
                    <router-link class="small text-decoration-none" to="/auth/login">Войти</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import InputText from 'primevue/inputtext'

export default {
  name: "LoginView",
  components: {InputText},
  data() {
    return {
      email: '',
      password: '',
      re_password: '',
      username: '',
      errorMessage: '',
      isLoading: false,
      emailInvalid: false,
      usernameInvalid: false,
      passwordInvalid: false,
    }
  },
  methods: {
    login() {
      const email = this.email;
      const password = this.password;
      const re_password = this.re_password;
      const username = this.username;
      if (!password.length || password !== re_password) {
        this.$store.commit('addToast', {text: 'Пароли не совпадают'})
        return
      }
      if (!email.length) {
        this.emailInvalid = true
      }
      if (!password.length) {
        this.passwordInvalid = true
      }
      if (!username.length) {
        this.usernameInvalid = true
      }
      for (let item of [password, email, username]) {
        if (!item.length) {
          return
        }
      }
      this.isLoading = true
      this.$store.dispatch('register', {email: email, password: password, username: username})
          .then(() => {
            this.$store.commit('addToast', {
              text: 'На указанную почту было отправлено сообщение с подтверждением электронного адреса.',
              timeout: false
            })
            this.$router.push('/')
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
            if (error.response.status === 400) {
              for (let err of Object.keys(error.response.data)) {
                const errorList = error.response.data[err]
                errorList.forEach((e, i) => {
                  this.$store.commit('addToast', {text: e})
                })
              }
            }
          })
    }
  }
}
</script>

<style scoped>
.logotype > img {
  height: 65px;
}

label {
  color: #7c8591;
}

.container {
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-section {
  padding: 30px;
  width: 500px;
  background: white;
  border-radius: 7px;
  box-shadow: 5px 5px 25px #dddddd;
}

.login__inner {
  padding: 10px 25px 25px;
}

.welcome-text {
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #4e4e4e;
}

.btn {
  display: block;
  width: 100%;
}

@media (max-width: 524px) {
  .login-section {
    width: 100%;
    max-height: unset;
  }

  .login__inner {
    padding: 0;
  }

  .welcome-text {
    font-size: 1.2em;
    margin: 20px 0;
  }

  .login-section {
    padding: 10px;
  }
}
</style>
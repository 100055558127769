<template>
  <destroy-confirmation-modal
      @itemDelete="onStageDelete"
      header-text="Вы уверены что хотите удалить этап проекта?"
      muted-text="Данные будут безвозвратно уничтожены"/>
</template>

<script>
import DestroyConfirmationModal from "@/application/components/UIComponents/DestroyConfirmationModal";
import {APIManager} from "@/common/helpers/api/manager";
export default {
  name: "StageDeleteView",
  components: {DestroyConfirmationModal},
  methods: {
    onStageDelete(event) {
      APIManager.destroy({model: 'stage', id: this.$route.params.stageId}).then(res => {
        let query = {}
        if (localStorage.getItem('archive')) {
          query.archive = 'true'
        }
        this.$router.push({name: 'boardDetail', params: {id: this.$route.params.id}, query: query})
      })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="checkbox-list">
    <div class="d-flex align-items-center mt-1 mb-2">
      <span class="me-2 fs-6 fw-bold">Подзадачи</span>
      <r-button icon-only
                @click="showCreate=false"
                v-if="showCreate"
                icon="minus"
                design="grey"
                size="small"/>
      <r-button icon-only
                @click="onCreateOpen"
                v-else
                icon="plus"
                design="grey"
                size="small"/>
    </div>
    <div class="items mb-1">
      <div v-if="itemList.length" class="mt-2">
        <checkbox-item class="mb-1"
                       @update="onUpdate"
                       @deleteCheck="onDelete"
                       :item="item"
                       v-for="item in itemList"/>
      </div>
      <div v-else>
        <p class="mb-0 text-muted text-pre-line">Добавьте пункты чек-листа</p>
      </div>
    </div>
    <div v-show="showCreate" class="pseudo mb-2 mt-2">
      <pseudo-checkbox ref="pseudo"
                       @create="onCreate"
                       :task-id="taskId"/>
    </div>
  </div>
</template>

<script>
import CheckboxItem from "@/application/components/ViewComponents/TaskComponents/CheckboxItem";
import {APIManager} from "@/common/helpers/api/manager";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import PseudoCheckbox from "@/application/components/ViewComponents/TaskComponents/PseudoCheckbox";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import RButton from "@/application/UIKit/RButton.vue";

export default {
  name: "CheckboxList",
  components: {RButton, PseudoCheckbox, FontAwesomeIcon, CheckboxItem, CircleProgress},
  data() {
    return {
      itemList: [],
      showCreate: false
    }
  },
  props: {
    taskId: String
  },
  methods: {
    onCreateOpen() {
      this.showCreate = true
      this.$nextTick(() => {
        this.$refs.pseudo.$el.querySelector('input').focus()
      })
    },
    onUpdate(checkbox) {
      const box = this.itemList.find(c => c.id === checkbox.id)
      if (box) {
        box.is_done = checkbox.is_done
      }
    },
    onCreate(checkbox) {
      this.itemList.push(checkbox)
    },
    onDelete(id) {
      this.itemList = this.itemList.filter(c => c.id !== id)
    },
    getItemList() {
      APIManager.get_checkboxes(this.taskId).then(res => this.itemList = res)
    }
  },
  mounted() {
    this.getItemList()
  },
  computed: {
    progress() {
      if (this.itemList) {
        const completed = this.itemList.filter(i => i.is_done).length
        return completed / this.itemList.length * 100
      }
      return 0
    },
  }
}
</script>

<style scoped>
.checkbox-btn:hover {
  background-color: #f1f1f1;
}

.checkbox-btn {
  box-shadow: none !important;
}
</style>
<template>
  <div ref="taskInfo"
       :class="['task-info', {'default-top': !outsideBottom, 'default-right': !outsideRight}]"
       class="task-info">
    <p class="text-center fw-bold mb-1">Сводка</p>
    <task-info-item header="Запланировано времени" :value="scheduledHours"/>
    <task-info-item header="Затрачено времени" :value="completedHours"/>
    <task-info-item header="Превышение" v-if="task.completion.overload" :value="taskOverload"/>
    <task-info-item header="Создатель">
      <r-user-item size="small" :user="task.creator"/>
    </task-info-item>
    <task-info-item header="Ответственный">
      <r-user-item size="small" :user="task.responsible_user"/>
    </task-info-item>
    <task-info-item header="Приоритет" :value="priorityValue"/>
    <task-info-item header="Подзадач запланировано" :value="allCheckboxes"/>
    <task-info-item header="Подзадач выполнено" :value="completedCheckboxes"/>
  </div>
</template>

<script>
import TaskInfoItem from "@/application/components/ViewComponents/TaskComponents/TaskInfoItem";
import RUserItem from "@/application/UIKit/RUserItem";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "TaskInfo",
  components: {FontAwesomeIcon, RUserItem, TaskInfoItem},
  props: {
    task: {
      type: Object,
      required: true,
      default: {
        creator: {},
        responsible_user: {},
        overload: null,
        checkboxes: {
          items: []
        }
      }
    },
    priorityValue: String,
    completedCheckboxes: Number,
    allCheckboxes: Number,
  },
  data() {
    return {
      outsideBottom: false,
      outsideRight: false,
    }
  },
  methods: {
    checkViewport() {
      this.$nextTick(() => {
        const element = this.$refs.taskInfo
        const rect = element.getBoundingClientRect()
        const outsideBottom = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
        const outsideRight = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
        const taskName = element.closest('.task')
        if (!outsideBottom) {
          element.style.bottom = taskName.offsetHeight + 10 + "px"
        }
        if (!outsideRight && outsideBottom) {
          element.style.right = 25 + 'px'
          element.style.bottom = 'unset'
          element.style.top = '25px'
        } else if (!outsideRight && !outsideBottom) {
          element.style.right = 25 + 'px'
        }

        this.outsideBottom = !outsideBottom
        this.outsideRight = !outsideRight
      })
    },
  },
  computed: {
    completedHours() {
      if (this.task.completion.time_hours || this.task.completion.time_minutes) {
        return this.time_verbose(this.task.completion.time_hours, this.task.completion.time_minutes)
      }
      return 'Нет'
    },
    scheduledHours() {
      if (this.task.scheduled_hours === 0 && this.task.scheduled_minutes === 0) {
        return 'Нет'
      }
      return this.time_verbose(this.task.scheduled_hours, this.task.scheduled_minutes)
    },
    taskOverload() {
      if (this.task.completion.overload) {
        return this.time_verbose(this.task.completion.overload.overload_hours, this.task.completion.overload.overload_minutes)
      }
      return 'Нет'
    },
  },
  created() {
    this.checkViewport()
  }

}
</script>

<style scoped>
.task-done {
  color: #00be00;
}

.task-incomplete {
  color: #dc0000;
}

.subtask-icon {
  font-size: 16px;
}

.task-info {
  position: absolute;

  z-index: 1500;
  background-color: #0f1a34;
  color: #f6f6f6;
  border-radius: 5px;
  min-width: 350px;
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: 5px 5px 15px rgba(15, 26, 52, 0.85);
}

.default-top {
  top: 30px;
}

.default-right {
  right: -50%;
}

.text-grey {
  color: #c3c3c3;
}
</style>
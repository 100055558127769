<template>
  <div class="breadcrumbs">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li v-for="item in items" :class="{active: item.isLast}" class="breadcrumb-item" aria-current="page">
          <router-link class="text-secondary" v-if="!item.isLast" :to="item.link">{{ item.text }}</router-link>
          <span v-else>{{ item.text }}</span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: "RBreadcrumbs",
  props: {
    items: Array
  }
}
</script>

<style scoped>
.breadcrumb-item:not(.active) a {
  border-bottom: 1px solid var(--text-color-secondary);
}
</style>
<template>
  <div class="search-bar">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256">
      <g fill="#525252" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
         stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
         font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <g transform="scale(5.33333,5.33333)">
          <path
              d="M20.5,6c-7.99037,0 -14.5,6.50964 -14.5,14.5c0,7.99036 6.50963,14.5 14.5,14.5c3.45636,0 6.63371,-1.22096 9.12891,-3.25l9.81055,9.81055c0.37623,0.39185 0.9349,0.54969 1.46055,0.41265c0.52565,-0.13704 0.93616,-0.54754 1.07319,-1.07319c0.13704,-0.52565 -0.0208,-1.08432 -0.41265,-1.46055l-9.81055,-9.81055c2.02904,-2.4952 3.25,-5.67255 3.25,-9.12891c0,-7.99036 -6.50963,-14.5 -14.5,-14.5zM20.5,9c6.36905,0 11.5,5.13096 11.5,11.5c0,3.10261 -1.2238,5.90572 -3.20898,7.9707c-0.12237,0.08994 -0.23037,0.19794 -0.32031,0.32031c-2.06499,1.98518 -4.86809,3.20898 -7.9707,3.20898c-6.36905,0 -11.5,-5.13096 -11.5,-11.5c0,-6.36904 5.13095,-11.5 11.5,-11.5z"></path>
        </g>
      </g>
    </svg>
    <input placeholder="Поиск" type="search" class="search-input">
  </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
  name: "SearchBar",
  components: {InputText},

}
</script>

<style scoped>
.search-bar {
  position: relative;
  padding: 5px 40px;
  background-color: white;
  border-radius: 20px;
  width: 450px;
}

.search-input {
  width: 100%;
  border: none;
  outline: none;
}

svg {
  max-height: 25px;
  max-width: 25px;
  position: absolute;
  left: 10px;
  top: 4px;
}

@media (max-width: 672px) {
  .search-bar {
    display: none;
  }

}
</style>
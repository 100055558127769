<template>
  <div class="r-list__wrapper">
    <div class="r-list-title" v-if="title">
      <font-awesome-icon class="me-2" v-if="titleIcon" :icon="titleIcon"/>
      <h6>{{ title }}</h6>
    </div>
    <table v-if="itemList.length" class="table bg-transparent r-list-table m-0">
      <thead>
      <tr>
        <th v-for="header in headerList">{{ header }}</th>
        <th v-if="useDelete"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in itemList">
        <td :class="{'use-delete': !!useDelete}" v-if="fieldList" v-for="field in fieldList">{{ item[field] }}</td>
        <td :class="{'use-delete': !!useDelete}" v-else v-for="index in Object.keys(item)">{{ item[index] }}</td>
        <td v-if="useDelete">
          <div class="d-flex">
            <div @click="$emit('itemDelete', item.id)" :class="{disabled: deleteDisabled}" class="del-item ms-auto">
              <font-awesome-icon :icon="['fas', 'trash']"/>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else class="mt-1">
      <p class="text-muted">{{ emptyMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RList",
  props: {
    title: {
      type: String
    },
    itemList: {
      type: Array,
      required: true
    },
    headerList: {
      type: Array,
      required: true
    },
    fieldList: {
      type: Array
    },
    design: {
      type: String
    },
    useDelete: {
      type: Boolean
    },
    titleIcon: {
      type: Array
    },
    emptyMessage: String,
    deleteDisabled: Boolean
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  background-color: #994b4b!important;
}

.r-list-title {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}

.r-list-title > h6 {
  margin: 0;
}

.del-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: darkred;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  font-size: 12px;
}

.del-item:hover {
  cursor: pointer;
  background-color: #5b0000;
}

.r-list__wrapper {
  border-radius: 5px;
  padding: 5px;
}

tbody:not(:first-child) {
  border-top: 1px solid;
  border-bottom: 1px solid #e7e7e7;
}

th {
  font-size: 14px;
  font-weight: bolder;
  color: #191c1f;
  padding: 5px;
}

td {
  font-size: 14px;
  padding: 5px;
  border-bottom: 0;
}

td.use-delete {
  padding-top: 7px;
}
</style>
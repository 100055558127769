<template>
  <li :class="{'active-link': activeLink}" class="main-nav-item">
    <router-link @click="menuClick" :to="link" class="main-nav-link">
      <font-awesome-icon :icon="icon"
                         class="me-2"/>
      <span class="main-link-text">{{ text }}</span>
    </router-link>
  </li>
</template>

<script>

export default {
  name: "SideBarMenuItem",
  props: {
    board: {
      type: Object,
      default: {}
    },
    link: {
      default: null
    },
    icon: String,
    text: String,
  },
  computed: {
    activeLink() {
      return this.$route.name === this.link.name || this.$route.path === this.link
    },
  },
  methods: {
    menuClick() {
      this.$store.commit("closeSidebar")
    },
  },
}
</script>

<style scoped>
</style>
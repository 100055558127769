<template>
  <div class="settings-content">
    <div class="header">
      <h5>Параметры проекта</h5>
      <div class="board-settings__wrapper">
        <div class="board-settings-menu board-settings__inner">
          <ul class="settings-menu-list">
            <li @click="chooseMenuItem(menuItem.value)" :class="{'menu-active': activeMenu === menuItem.value}"
                v-for="menuItem in menuItems" class="settings-menu-item">{{ menuItem.title }}
            </li>
          </ul>
        </div>
        <div class="board-settings-content board-settings__inner">
          <div v-if="activeMenu==='general'">
            <h5 class="mb-3">Основные настройки проекта</h5>
            <div class="board-settings-title mb-3">
              <label class="form-label" for="id_board_title">Название проекта</label>
              <input @change="renameBoard(board.title)"
                     :class="{'is-invalid': titleInvalid}"
                     v-model="board.title"
                     id="id_board_title"
                     class="form-control form-control-sm">
            </div>
            <div class="board-settings-title mb-3">
              <label class="form-label" for="id_board_budget">Бюджет проекта</label>
              <input @change="changeBudget(board.budget)"
                     placeholder="Введите число"
                     :class="{'is-invalid': budgetInvalid}"
                     v-model="board.budget"
                     id="id_board_budget"
                     class="form-control form-control-sm">
            </div>
            <board-member-suggestion :board_id="board.id" @memberInvite="onMemberInvite"></board-member-suggestion>
            <r-list :field-list="['username', 'email']"
                    :empty-message="'Нет участников'"
                    @itemDelete="onItemDelete"
                    :title-icon="['fas', 'user']"
                    :delete-disabled="!canEditBoard"
                    :use-delete="true"
                    title="Участники"
                    :header-list="['Имя', 'Email']"
                    :itemList="boardMembers"></r-list>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RList from "@/application/UIKit/RList";
import BoardMemberSuggestion from "@/application/components/ViewComponents/BoardComponents/BoardMemberSuggestion";
import {APIManager} from "@/common/helpers/api/manager";
import {InviteManager} from "@/common/helpers/api/invite_members";
import PermissionList from "@/application/components/ViewComponents/BoardComponents/PermissionList";

export default {
  name: "BoardSettings",
  components: {PermissionList, BoardMemberSuggestion, RList},
  data() {
    return {
      board: {
        creator: {
          id: null
        }
      },
      budgetInvalid: false,
      titleInvalid: false,
      newMemberEmail: '',
      menuItems: [
        {title: 'Основное', icon: '', value: 'general'},
      ],
      activeMenu: new URLSearchParams(window.location.search).get('section') || 'general'
    }
  },
  methods: {
    onItemDelete(id) {
      InviteManager.exclude(this.board.id, id).then(res => {
        this.board = res
      }).catch(err => console.log(err))
    },
    onMemberInvite(board_data) {
      this.board = board_data
    },
    chooseMenuItem(section) {
      this.activeMenu = section
      const url = '?' + 'section=' + section
      history.pushState('', '', url)
    },
    getBoardData() {
      APIManager.getBoardSettingsData({'model': 'board', id: this.$route.params.id}).then(res => {
        this.board = res
      })
    },
    updateBoard(data) {
      APIManager.update({model: 'board', id: this.$route.params.id, data: data}).then(res => {
        this.board = res
      }).catch(err => console.log(err))
    },
    renameBoard(title) {
      this.titleInvalid = false
      if (!title || !title.length) {
        this.titleInvalid = true
        return
      }
      this.updateBoard({title: title})
    },
    changeBudget(budget) {
      this.budgetInvalid = false
      if (!budget) {
        this.budgetInvalid = true
        return
      }
      this.updateBoard({budget: budget})
    }
  },
  computed: {
    canEditBoard() {
      if (this.board.creator.id === this.$store.state.user.id) {
        return true
      }
      return this.userHasPermission('edit_board')
    },
    boardMembers: {
      get() {
        return this.board.members ? this.board.members.filter(member => member.id !== this.board.creator.id) : []
      }
    },
  },
  created() {
    this.getBoardData()
  }
}
</script>

<style scoped>
.settings-menu-item {
  padding: 5px 7px;
  font-size: 14px;
  color: #4c4c4c;
  background-color: #fff;
  margin-bottom: 3px;
  border-radius: 5px;
}

.settings-menu-item:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.settings-menu-item.menu-active {
  background-color: #30303d;
  color: white;
}

.settings-menu-item.menu-active:hover {
  background-color: #282828;
}

.header {
  min-width: 60vw;
}

.board-settings-menu {
  min-width: 250px;
  border-right: 1px solid lightgray;
}

.board-settings__wrapper {

  border-radius: 5px;
  border: 1px solid lightgray;
}

.board-settings__inner {
  padding: 10px;
}

.settings-menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.board-settings__wrapper {
  display: flex;
  min-height: 350px;
}

.board-settings-content {
  width: 100%;
}
</style>
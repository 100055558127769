<template>
    <div class="r-toast__wrapper">
        <div class="r-toast">
            <div class="r-toast-body">
                <p class="body-text">{{ bodyText }}</p>
            </div>
            <div @click="remove" class="r-close">
                <font-awesome-icon icon="fa-solid fa-xmark"/>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "ToastItem",
    props: {
      bodyText: {
        type: String
      },
      timeout: Number|Boolean
    },
    methods: {
      remove() {
        this.$emit('remove')
      }
    },
    mounted() {
      if (this.timeout === false) {
        return
      }
      setTimeout(() => this.$emit('remove'), 3500)
    }
  }
</script>

<style scoped>
    .r-toast__wrapper {
        transition: all .3s ease;
    }
    .r-toast {
        background-color: #191c1f;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-size: .9em;
        border-radius: 7px;
        margin: 3px 0;
    }
    .r-toast-body {
        padding: 7px;
    }
    .body-text {
        padding: 0;
        margin: 0 10px 0 0;
    }
    .r-close {
        z-index: 9999;
    }
    .r-close:hover {
        cursor: pointer;
    }
</style>
import axios from "axios";
import store from "@/store/index"

export const InviteManager = {
  invite: (boardId, userEmail) => {
    const url = `${store.getters.API_URL}/members/invite`
    const data = {
      board_id: boardId,
      user_email: userEmail
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  },

  exclude: (boardId, id) => {
    const url = `${store.getters.API_URL}/members/exclude`
    const data = {
      user_id: id,
      board_id: boardId
    }
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(res => {
        resolve(res.data)
      }).catch(err => {
        store.commit('addToast', {text: err.response.data.user})
        reject(err)
      })
    })
  }
}
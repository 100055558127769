<template>
  <div @click.stop.prevent="onItemClick" :class="{unread: !item.is_read}" class="notification-item py-2 px-3">
    <div class="d-flex align-items-center">
      <div class="n-avatar mb-auto mt-2">
        <img height="35"
             width="35"
             class="rounded-circle me-2"
             :src="item.sender.avatar"
             alt="avatar">
      </div>
      <div class="n-body">
        <div class="n-header">
          <div class="very-small fw-bold">{{ item.sender.username }}</div>
          <div class="ms-auto">
            <p class="m-0 text-muted very-small">{{ item.created_at }}</p>
          </div>
        </div>
        <div class="n-title">
          <span class="very-small text-muted">{{ item.title }}</span>
        </div>
        <div class="n-description">
          <span class="small">{{ cutString(item.text, 150) }}</span>
        </div>
      </div>
      <div @click="readNotification" v-if="!item.is_read" class="n-end text-success ms-2 ms-auto">
        <div class="px-1">
          <font-awesome-icon :icon="['fas', 'check']"/>
        </div>
      </div>
    </div>
    <div class="n-footer d-flex align-items-center">
    </div>
  </div>
</template>

<script>
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "UserNotificationItem",
  methods: {
    onItemClick() {
      this.$router.push(this.item.action_link)
      this.onOpen()
    },
    readNotification() {
      APIManager.update({model: 'notification', id: this.item.id, data: {is_read: true}}).then(res => {
        this.$emit('update', res)
      })
    },
    onOpen() {
      return new Promise((resolve, reject) => {
        this.$emit('open')
        resolve()
      })
    },
    cutString(str, short_by) {
      return str && str.length > short_by ? str.substring(0, short_by) + '...' : str
    },
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.n-header {
  display: flex;
}
.n-body {
  width: 100%;
}
.n-description > span {
  display: block;
  line-height: 21px;
  font-size: 14px;
}

.notification-item {
  border: 1px solid #efefef;
  max-width: 400px;
  border-radius: 7px;
  transition: background-color .1s linear;
}

.notification-item:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.notification-item.unread {
  position: relative;
}

.notification-item.unread:after {
  border-radius: 50%;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 5px;
  right: 0;
  background-color: #0080ff;
  content: "";
}

.n-end:hover {
  cursor: pointer;
  background: #ececec;
  border-radius: 5px;
}

.unread .n-end:hover {
  background: #f4f4f4;
}
</style>
<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-b-dark">
    <div class="container-fluid">
      <div class="toggle-sidebar__wrapper">
        <div class="clickable" @click="$store.commit('toggleSidebar')">
          <font-awesome-icon icon="fa-solid fa-bars" class="sidebar-bars"/>
        </div>
      </div>
      <div class="search-bar ms-auto">
        <search-bar/>
      </div>
      <div class="navbar-nav ms-auto">
        <div class="user d-flex align-items-center me-3">
          <r-user-item color="white" :user="$store.state.user"/>
        </div>
        <div class="nav-item">
          <button @click="logout" class="exit-btn">
            <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="me-1"/>
            Выйти
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import UserNotifications from "@/application/components/ViewComponents/NotificationComponents/UserNotifications";
import SearchBar from "@/application/components/MiscComponents/SearchBar";
import RUserItem from "@/application/UIKit/RUserItem";

export default {
  name: "TopBar",
  components: {RUserItem, SearchBar, UserNotifications},
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/auth/login');
      })
    },
  }
}
</script>

<style scoped>

.navbar-nav {
  flex-direction: row !important;
}

.exit-btn {
  padding: 7px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: #373063e6;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color .2s;
}

.exit-btn:hover {
  background-color: #594f98;
}

.navbar {
  height: 60px;
}

.sidebar-bars {
  font-size: 23px;
  color: #eaeaea;
  margin-left: 10px;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  transition: background-color .2s ease-in-out;
}

.sidebar-bars:hover {
  background-color: #0e2848;
}

.sidebar-arrow {
  font-size: 26px;
  transition: .3s;
}

.arrow-rotate {
  transform: rotate(180deg);
}
</style>
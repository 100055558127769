<template>
  <div>
    <router-view></router-view>
  </div>
  <toast-list/>
  <transition name="popup">
    <app-loader v-if="!appLoaded"/>
  </transition>
</template>

<script>
import ToastList from "@/application/components/ViewComponents/ToastComponents/ToastList";
import AppLoader from "@/application/components/MiscComponents/AppLoader";
import CustomModal from "@/application/components/UIComponents/CustomModal";
import {useRoute} from "vue-router";

export default {
  components: {CustomModal, AppLoader, ToastList},
  data() {
    return {}
  },
  async created() {
    let token = this.$store.state.token
    if (!token) {
      return
    }
    this.$store.dispatch("get_user", token).then(user => {
      this.$store.commit("auth_success", token)
      this.$store.commit("set_user", user)
    }).catch(error => console.log(error))
  },
  computed: {
    appLoaded() {
      const allowedPaths = [
        "login",
        "register",
        "authConfirm",
        "notFound"
      ]
      const route = this.$store.state.route
      if (route && allowedPaths.includes(route)) {
        return true
      }
      return this.$store.getters.LOADED
    }
  }

}
</script>
<style>
html {
  overflow-y: hidden;
  background-color: #eeeeee;
}

button {
  user-select: none;
}

</style>
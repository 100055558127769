<template>
  <div @click.stop="onClick" :class="{'active': isChosen}" class="preset">
    <div class="preset-header">
      <p class="preset-name">{{ preset.name }}</p>
    </div>
    <div class="preset-body">
      <ul class="stage-list">
        <li v-for="stage in preset.stages" class="list-item">{{ stage.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoardPresetItem",
  props: ['preset', 'isChosen'],
  methods: {
    onClick() {
      this.$emit(this.isChosen ? 'unselect' : 'select', this.preset)
    },
  }
}
</script>

<style scoped>
.preset-header {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preset-name {
  margin-bottom: 0;
}

.stage-list {
  margin-bottom: 0;
  list-style-type: decimal;
  font-size: 14px;
  margin-left: 25px!important;
}

.preset-name {
  margin-right: 30px;
}

.preset {
  vertical-align: top;
  padding: 0 12px 12px;
  border-radius: 10px;
  border: 1px solid lightgray;
  display: inline-block;
  transition: box-shadow .1s linear;
}

.preset:not(:first-child) {
  margin-left: 9px;
}

.preset:hover {
  cursor: pointer;
}

.preset:hover:not(.active) {
  box-shadow: 0 0 5px 0 rgba(86, 84, 84, 0.3);
}

.preset.active {
  border: 1px solid #9f9f9f;
  box-shadow: 0 0 5px 0 rgba(155, 129, 255, 0.7);
}
</style>
<template>
  <div>
    <div class="p-3 p-md-5 bg-white rounded-1 shadow-sm r-scroll main-dash">
      <Dashboard @createBoard="handleCreateBoardModal"/>
    </div>
    <custom-modal @modalClose="closeCreateBoardModal" v-bind:show-modal-prop="showCreateBoardModal">
      <create-board-window @modalClose="closeCreateBoardModal"/>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "@/application/components/UIComponents/CustomModal";
import CreateBoardWindow from "@/application/components/ViewComponents/BoardComponents/CreateBoardWindow";
import VueTitle from "@/application/components/MiscComponents/VueTitle";
import Dashboard from "@/application/components/Dashboard/Dashboard.vue";
import RButton from "@/application/UIKit/RButton.vue";

export default {
  name: "Main",
  components: {RButton, Dashboard, VueTitle, CreateBoardWindow, CustomModal},
  data() {
    return {
      showCreateBoardModal: false,
    }
  },
  methods: {
    closeCreateBoardModal() {
      this.showCreateBoardModal = false
    },
    handleCreateBoardModal() {
      this.showCreateBoardModal = true
    },
  }
}
</script>

<style scoped>
.main-dash {
  overflow-y: auto;
  max-height: 90vh;
}
</style>
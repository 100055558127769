import axios from "axios";
import store from "@/store";
import {handleErrorResponse} from "@/common/helpers/api/utils";

export const dashboard = {
    async getDashboardBoardList() {
        if (!store.state.token) {
            return
        }
        const url = `${store.state.apiUrl}/dashboard`
        try {
            return await axios.get(url, {headers: {Authorization: `Token ${store.state.token}`}})
        } catch (e) {
            handleErrorResponse(e)
        }
    },
    async refreshBoardList() {
        if (!store.state.token) {
            return
        }
        const url = `${store.state.apiUrl}/dashboard/projects`
        try {
            return await axios.get(url, {headers: {Authorization: `Token ${store.state.token}`}})
        } catch (e) {
            handleErrorResponse(e)
        }
    }
}
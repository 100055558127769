<template>
  <div class="dropdown-main"
       :class="{'lg': size === 'lg'}"
       ref="dropdown"
       tabindex="0"
       @focusout="onClickOutside">
    <div @click="onOpen"
         :class="getDisplayClass()"
         class="dropdown-selection">
      <span class="dropdown-display default-text-present">{{ getSelectedDisplayName() }}</span>
      <span v-if="useIcons" class="dropdown-icon icon-default ms-2"><font-awesome-icon icon="fa-solid fa-flag"/></span>
    </div>
    <div ref="dropdown" v-if="isOnSelect" :style="{left: left ? `${left}px` : 0}" class="selectable">
      <ul class="dropdown">
        <li @click="itemSelected(item[lookupField])"
            :key="item.value" v-for="item in itemList"
            :class="[{ 'item-selected': checkSelected(item[lookupField])}, item.classname]"
            class="dropdown-item">
          <div v-if="useImage" class="item-image__wrapper">
            <img height="30" width="30" :src="getImageSrc(item)" alt="image"
                 class="item-image rounded-circle me-2">
          </div>
          <span class="default-text">{{ getDisplayName(item) }}</span>
          <span v-if="useIcons" class="dropdown-icon ms-2"><font-awesome-icon icon="fa-solid fa-flag"/></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownSelect",
  data() {
    return {
      isOnSelect: false,
    }
  },
  props: {
    size: String,
    disabled: Boolean,
    left: Number,
    actualValue: {},
    itemList: {
      type: Array,
      required: true
    },
    displayNameField: {
      type: String
    },
    lookupField: {
      type: String,
      required: true
    },
    useIcons: {
      type: Boolean
    },
    useImage: {
      type: Boolean
    },
    imageField: {
      type: String
    },
    emitKey: {
      type: String,
      required: true
    }
  },
  methods: {
    onOpen() {
      if (this.disabled) {
        return
      }
      this.isOnSelect = !this.isOnSelect
    },
    onClickOutside() {
      this.isOnSelect = false
    },
    getSelectedDisplayName() {
      let options = this.itemList.filter(item => item[this.lookupField] === this.actualValue)[0]
      if (!options) {
        return "Не указано"
      }
      return options[this.displayNameField]
    },
    checkSelected(value) {
      if (value === this.actualValue) {
        return true
      }
      return null
    },
    getDisplayClass() {
      let options = this.itemList.filter(pr => pr.value === this.actualValue)
      if (options.length) {
        return options[0].classname
      }
      return null
    },
    closeSelect() {
      this.isOnSelect = false
    },
    itemSelected(value) {
      this.closeSelect()
      if (this.actualValue === value) {
        return
      }
      if (this.disabled) {
        return
      }
      let key = this.emitKey
      const emitObject = {[`${key}`]: value}
      this.$emit('itemSelected', emitObject)
    },
    getDisplayName(object) {
      return object[this.displayNameField]
    },
    getImageSrc(object) {
      if (this.imageField) {
        return object[this.imageField]
      }
      return object['image']
    },
  },
}
</script>

<style scoped>

.selectable {
  position: absolute;
  top: 5px;
  right: -5px;
  z-index: 6000;
}

.default-text-present, .default-text {
  font-size: 14px;
}

.default-text-present {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-image {
  height: 20px;
  width: 20px;
}

.item-selected:before {
  display: block;
  position: relative;
  right: 10px;
  content: "";
  height: 7px;
  width: 7px;
  background-color: #919191;
  border-radius: 50%;
  margin-left: 1rem;
}

.dropdown-selection {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.circle-secondary .dropdown-icon {
  color: gray;
}

.circle-info .dropdown-icon {
  color: #17a2b8;
}

.circle-warning .dropdown-icon {
  color: orange;
}

.circle-danger .dropdown-icon {
  color: darkred;
}

.dropdown-icon {
  font-size: .8em;
}

.icon-default {
  font-size: 1em;
}

.dropdown {
  z-index: 99;
  position: absolute;
  top: -10px;
  right: 0;
  border: 1px solid lightgray;
  list-style: none;
  padding: 0;
  text-align: right;
  background-color: white;
  box-shadow: 0 0 5px lightgray;
  margin: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 5px 20px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-main {
  position: relative;
}

.dropdown-main.lg {
  padding: 6px 13px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #bebebe;
}
.lg .dropdown-selection {
  min-width: 120px;
  justify-content: end;
}
</style>
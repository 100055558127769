<template>
  <div @focusout="onFocusOut" tabindex="3" class="notifications">
    <div v-if="active" class="n-arrow"></div>
    <div class="notifications__inner">
      <span class="badge bg-primary new-notification-badge" v-if="hasNotifications">{{ hasNotifications }}</span>
    </div>
    <div @click.stop="toggleActive" class="notification-icon text-white">
      <font-awesome-icon :icon="['fas', 'bell']" />
    </div>
    <div v-if="active" class="notifications-list">
      <div class="list-header mb-2 d-flex align-items-center justify-content-between border-bottom">
        <div class="p-2 small me-5">
          <font-awesome-icon class="text-muted" :icon="['fas', 'bell']" />
          <span class="ms-2 fw-bolder text-muted">Уведомления</span>
        </div>
        <div class="p-2 small opacity-0 no-events">Отметить прочитанными</div>
      </div>
      <user-notification-item class="mb-1" @update="notificationUpdate" @open="active=false" :item="item" v-for="item in notificationList" v-if="notificationList.length"/>
      <div v-else class="notification-item p-3">
        <p class="mb-0 text-muted">Уведомлений нет</p>
      </div>
    </div>
  </div>
</template>

<script>
import {APIManager} from "@/common/helpers/api/manager";
import UserNotificationItem from "@/application/components/ViewComponents/NotificationComponents/UserNotificationItem";

export default {
  name: "UserNotifications",
  components: {UserNotificationItem},
  data() {
    return {
      notificationList: [],
      active: false,
    }
  },
  methods: {
    notificationUpdate(notification) {
      for (let n of this.notificationList) {
        if (n.id === notification.id) {
          Object.assign(n, notification)
        }
      }
    },
    onFocusOut() {
      setTimeout(() => {
        this.active = false
      }, 100)
    },
    toggleActive() {
      this.active = !this.active
    },
    getNotifications() {
      if (!this.$store.state.token) {
        return
      }
      APIManager.list({model: 'notification'}).then(res => {
        this.notificationList = res
      })
    },
    runNotifications() {
      this.getNotifications()
      setTimeout(() => {
        this.runNotifications()
      }, 10000)
    }
  },
  created() {
    this.runNotifications()
  },
  computed: {
    hasNotifications() {
      return this.notificationList.filter(notification => !notification.is_read).length
    },
    notificationsCount(){
      return this.hasNotifications()
    }
  }
}
</script>

<style scoped>
.n-arrow:after {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  background-color: white;
  position: absolute;
  top:50px;
  transform: rotateZ(45deg);
  right: 218px;
}
.notification-icon {
  height: 20px;
}
.notification-icon:hover {
  cursor: pointer;
}
.notifications__inner {
  position: relative;
}
.new-notification-badge {
  position: absolute;
  top: -10px;
  left: -15px;
}
.notification-icon {
  font-size: 18px;
}
.notifications-list {
  background: white;
  z-index: 5000;
  position: absolute;
  top: 55px;
  right: 150px;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,.3);
  padding: 5px;
}
</style>
<template>
  <AvatarGroup>
    <Avatar v-for="user in userList" :image="user.avatar" :size="size" shape="circle"/>
<!--    <Avatar label="+2" shape="circle" size="large"/>-->
  </AvatarGroup>
</template>

<script>
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";

export default {
  name: "UserAvatarList",
  components: {AvatarGroup, Avatar},
  props: {
    userList: {
      type: Array,
      required: true,
      default: []
    },
    size: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <vue-title title="Табели"/>
  <div class="r-list r-scroll">
    <div class="p-3">
      <div class="header">
        <div class="row">
          <div class="d-flex align-items-center">
            <h1 class="h2 me-2">Табели</h1>
            <div class="ms-auto">
              <r-button @click="openExport" icon="download" text="Экспорт"/>
            </div>
          </div>
        </div>
        <p class="mb-1 text-muted small">{{ countText }}</p>
        <p v-if="totalTimeText" class="mb-1 text-muted small">{{ totalTimeText }}</p>
        <p v-if="pageTimeText" class="mb-1 text-muted small">{{ pageTimeText }}</p>
      </div>
      <div class="filters my-3">
        <form action="/frontend/taskmgr/public" @submit.prevent="getReportData()" ref="filterForm" class="row">
          <button class="d-none" type="submit">Найти</button>
          <div :class="formGroupClass">
            <label class="form-label me-2 d-block" for="id_title">Проект</label>
            <Dropdown @update:modelValue="submitForm"
                      showClear
                      placeholder="Выберите проект"
                      class="w-100"
                      optionLabel="title"
                      optionValue="id"
                      :options="boards"
                      v-model="searchBoard"/>
          </div>
          <div :class="formGroupClass">
            <label class="form-label me-2" for="id_title">Название задачи</label>
            <InputText showClear @change="submitForm" placeholder="Введите название" class="d-block w-100" id="id_title"
                       type="text" v-model="searchTask"/>
          </div>
          <div :class="formGroupClass">
            <div>
              <label class="form-label me-2 d-block" for="id_date_from">Дата от:</label>
              <datepicker :full-month-name="true"
                          :input-class="'report-date-input'"
                          class="report-date"
                          language="ru"
                          monday-first
                          @update:model-value="submitForm"
                          :clear-button="true"
                          v-model="dateFrom"
                          placeholder="Дата от"
                          :typeable="false"
                          :hideInput="false"/>
            </div>
          </div>
          <div :class="formGroupClass">
            <label class="form-label me-2 d-block" for="id_date_to">Дата до:</label>
            <datepicker :full-month-name="true"
                        :input-class="'report-date-input'"
                        class="report-date"
                        language="ru"
                        monday-first
                        @update:model-value="submitForm"
                        :clear-button="true"
                        v-model="dateTo"
                        placeholder="Дата до"
                        :typeable="false"
                        :hideInput="false"/>
          </div>
          <div :class="formGroupClass">
            <label class="form-label">Пользователь</label>
            <div class="position-relative d-flex">
              <Dropdown @update:modelValue="submitForm" inputClass="search-user"
                        class="w-100"
                        showClear
                        placeholder="Выберите пользователя"
                        optionLabel="username"
                        optionValue="id"
                        :options="users"
                        v-model="searchUser"/>
            </div>
          </div>
          <div :class="formGroupClass">
            <label class="form-label">Быстрый выбор</label>
            <div class="position-relative d-flex">
              <Dropdown showClear @change="selectDateRange" class="w-100" placeholder="Выберите период"
                        optionLabel="text" :options="presetItems" v-model="searchPeriod"/>
            </div>
          </div>
        </form>
      </div>
      <div v-if="!loading" class="report-list">
        <div class="table-responsive">
          <div v-if="reportList.length">
            <table class="table r-table">
              <thead>
              <tr>
                <th>#</th>
                <th>Дата</th>
                <th>Проект</th>
                <th>Задача</th>
                <th>Описание</th>
                <th>Пользователь</th>
                <th>Время</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(report, index) in reportList">
                <td>{{ index + 1 }}</td>
                <td><p class="mb-0 mt-1">{{ report.date_display }}</p></td>
                <td>
                  <div class="r-task-link mt-1">
                    <router-link class="me-2"
                                 :to="{name: 'boardDetail', params: {id: report.board.id}}">
                      {{ report.board.title }}
                    </router-link>
                    <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                  </div>
                </td>
                <td>
                  <div class="r-task-link mt-1">
                    <router-link class="me-2"
                                 :to="{name: 'taskDetail', params: {id: report.board.id, taskId: report.task_id}}">
                      {{ report.task.title }}
                    </router-link>
                    <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                  </div>
                </td>
                <td v-tooltip.top="getTooltipText(report.description)">{{ cutString(report.description, 30) }}</td>
                <td>
                  <div class="mt-1">
                    <r-user-item size="small" :user="report.creator"/>
                  </div>
                </td>
                <td>{{ reportText(report) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3" v-else>
            <p class="text-center">Нет записей</p>
          </div>
          <div v-if="pageCount > 1" class="r-pagination d-flex justify-content-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li @click="paginationClick(page)" v-for="page in pageCount" :class="{active: page === currentPage}"
                    class="page-item"><a class="page-link" href="#">{{ page }}</a></li>
                <li v-if="currentPage !== pageCount" @click="paginationClick(currentPage + 1)" class="page-item">
                  <a class="page-link" href="#">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-center justify-content-center mt-5">
          <div class="spinner-border"/>
        </div>
      </div>
    </div>
    <transition name="popup">
      <view-modal @modalClose="handleCloseModal">
        <router-view @modalClose="handleCloseModal"/>
      </view-modal>
    </transition>
  </div>
</template>

<script>
import ReportTable from "@/application/components/ViewComponents/TaskComponents/ReportTable";
import {APIManager} from "@/common/helpers/api/manager";
import RUserItem from "@/application/UIKit/RUserItem";
import AppLoader from "@/App";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import InputText from "primevue/inputtext";
import RButton from "@/application/UIKit/RButton";
import Datepicker from 'vuejs3-datepicker';
import VueTitle from "@/application/components/MiscComponents/VueTitle";
import {cleanObject} from "@/common/helpers/api/utils";
import ViewModal from "@/application/components/UIComponents/ViewModal.vue";


export default {
  name: "ReportList",
  components: {
    ViewModal,
    VueTitle,
    InputText,
    Calendar,
    Button,
    FontAwesomeIcon,
    AppLoader,
    ReportTable,
    RUserItem,
    Dropdown,
    RButton,
    Datepicker
  },
  data() {
    return {
      reportList: [],
      dateFrom: new URLSearchParams(window.location.search).get("date_from"),
      dateTo: new URLSearchParams(window.location.search).get("date_to"),
      searchUser: new URLSearchParams(window.location.search).get("creator"),
      searchBoard: new URLSearchParams(window.location.search).get("board"),
      searchTask: new URLSearchParams(window.location.search).get("title"),

      hasPrevious: false,
      nextUrl: null,
      count: 0,
      users: [],
      boards: [],

      perPage: 20,
      currentPage: 1,
      loading: true,
      totalTime: 0,
      pageTotalTime: 0,
      presetItems: [
        {text: 'Сегодня', value: 'today'},
        {text: 'На этой неделе', value: 'week'},
        {text: 'В этом месяце', value: 'month'},
      ],

      searchPeriod: null,
      showExportModal: false
    }
  },
  methods: {
    getTooltipText(text) {
      return text === "/" ? null : text
    },
    openExport() {
      this.$router.push({name: "reportExport", query: this.$route.query})
    },
    handleCloseModal() {
      this.showExportModal = false
      this.$router.push({name: "reportList", query: this.$route.query})
    },
    selectDateRange() {
      const today = new Date()
      let valueFrom, valueTo
      if (!this.searchPeriod) {
        this.dateFrom = null
        this.dateTo = null
        this.getReportData()
        return
      }
      const rangePreset = this.searchPeriod.value
      if (rangePreset === 'today') {
        valueFrom = today
        valueTo = today
      } else if (rangePreset === 'week') {
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Устанавливаем первый день недели
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
        valueFrom = startOfWeek
        valueTo = endOfWeek
      } else if (rangePreset === 'month') {
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        valueFrom = startOfMonth
        valueTo = endOfMonth
      }
      this.dateFrom = valueFrom
      this.dateTo = valueTo
      this.submitForm()
    },
    submitForm() {
      const event = new Event("submit")
      this.$nextTick(() => {
        this.$refs.filterForm.dispatchEvent(event)
      })
    },
    paginationClick(page) {
      this.getReportData(page)
    },
    getReportData(page_arg) {
      this.loading = true
      const page = page_arg ? page_arg : 1
      const data = {
        board: this.searchBoard,
        title: this.searchTask,
        date_from: this.formatDate(this.dateFrom),
        date_to: this.formatDate(this.dateTo),
        creator: this.searchUser,
        page: page
      }

      const url = new URL(window.location.href)
      const params = new URLSearchParams(cleanObject(data))
      const path = `${url.pathname}?${params.toString()}`
      this.$router.push(path)

      APIManager.getReportList(data).then(res => {
        this.nextUrl = res.next
        this.reportList = res.results
        this.count = res.count
        this.loading = false
        this.pageTotalTime = res.page_total_time
        this.totalTime = res.total_time
        this.currentPage = page
      })
    },
    getUsers() {
      APIManager.getReportMeta().then(res => {
        this.users = res.members
        this.boards = res.boards
      })
    },
    reportText(report) {
      return this.time_verbose(report.time_hours, report.time_minutes)
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.count / this.perPage);
    },
    countText() {
      const verbose = this.number_as_verbose_string(this.count, ['запись', 'записи', 'записей'])
      const str = this.count.toString()
      return 'Всего' + ' ' + str + ' ' + verbose
    },
    pageTimeText() {
      if (this.pageTotalTime) {
        const hours = Math.floor(this.pageTotalTime / 60)
        const minutes = this.pageTotalTime % 60
        return 'На странице:' + ' ' + this.time_verbose(hours, minutes)
      }
    },
    totalTimeText() {
      if (this.totalTime) {
        const hours = Math.floor(this.totalTime / 60)
        const minutes = this.totalTime % 60
        return 'Общее время:' + ' ' + this.time_verbose(hours, minutes)
      }
    },
    formGroupClass() {
      return 'form-group col-12 col-md-6 col-lg-2 mt-2 mt-lg-0'
    }
  },
  created() {
    this.getReportData()
    this.getUsers()
  }
}
</script>

<style scoped>

.r-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  padding: 25px;
  background-color: white;
  border-radius: 5px;
  min-height: 90vh;
}

.r-table td {
  font-size: 14px;
  padding: 5px 10px;
}

.r-task-link {
  color: #49107f;
  display: flex;
  align-items: center;
}

.r-task-link a {
  color: #373063;
  text-decoration: none;
}

</style>
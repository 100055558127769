<template>
  <div :style="cardStyle" class="dashboard-card rounded-3 shadow-sm">
    <div class="dash-param-head">
      <p>{{ header }}</p>
    </div>
    <div class="dash-param-body">
      <p :class="color">{{ displayValue }}<span v-if="money"></span></p>
    </div>
  </div>
</template>

<script>
import {formatCurrency} from "@/common/helpers/api/utils";

export default {
  name: "DashboardItemNumber",
  props: {
    header: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    color: {
      default: "dark"
    },
    money: Boolean,
    background: String
  },
  data() {
    return {}
  },
  computed: {
    cardStyle() {
      if (!this.background) {
        return {}
      }
      const mapping = {
        blue: "url('./dash_card_background.png')",
        green: "url('./dash_card_background_green.png')",
      }
      const imagePath = mapping[this.background]
      return {"background-image": imagePath}
    },
    displayValue() {
      if (this.money) {
        return formatCurrency(this.value)
      }
      return this.value
    }
  }
}
</script>

<style scoped>
.dashboard-card {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  padding: 18px;
  border: 1px solid #f1f1f1;
}

.dash-param-head p {
  font-size: 16px;
  color: var(--color-text-black);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dash-param-body p {
  font-size: 28px;
  margin: 0;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dash-param-body p.dark {
  color: var(--color-text-dark)
}

.dash-param-body p.primary {
  color: var(--primary-color)
}

.dash-param-body p.sky {
  color: #80b2ff;
}
</style>
<template>
    <div class="not-found">
        <div class="mb-5">
            <h1 class="not-found-numbers">404</h1>
            <div class="header">
                <p class="h1 text-center mt-3">Страница не найдена</p>
            </div>
            <div class="buttons d-flex mt-3">
                <router-link :to="{ name: 'main' }" class="btn btn-dark mx-auto">На главную</router-link>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "NotFound"
  }
</script>

<style scoped>
    .not-found-numbers {
        text-align: center;
        font-size: 128px;
        font-weight: bold;
        color: #2d2d2d;
        margin: 0;
    }

    .not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100svh;
    }
</style>
<template>
  <div class="stage-settings">
    <div class="header">
      <h5>Настроки этапа</h5>
    </div>
    <div class="settings-body">
      <form @submit.prevent="onFormSubmit">
        <div class="form-group mb-3">
          <label class="form-label" for="id_title">Название этапа</label>
          <input placeholder="Название этапа"
                 @change="updateStage({title: stage.title})"
                 class="form-control"
                 type="text"
                 v-model="stage.title"
                 name="title"
                 id="id_title">
        </div>
        <div class="form-group mb-3">
          <div class="form-check">
            <input @change="updateStage({mark_as_done: stage.mark_as_done})"
                   class="form-check-input" type="checkbox" v-model="stage.mark_as_done" id="id_mark_as_done">
            <label class="form-check-label" for="id_mark_as_done">
              Отмечать задачи выполненными
            </label>
          </div>
          <p class="small text-muted mb-1">Задачи, перешедшие на этот этап или созданные на нём, автоматически будут
            отмечены как
            завершенные</p>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input @change="updateStage({set_archive: stage.set_archive})"
                   class="form-check-input" type="checkbox" v-model="stage.set_archive" id="id_set_archive">
            <label class="form-check-label" for="id_set_archive">
              Перемещать в архив
            </label>
          </div>
          <p class="small text-muted mb-1">Задачи, перешедшие на этот этап будут перемещены в архив</p>
        </div>
        <div class="mt-3">
          <button @click="$emit('modalClose')" type="button" class="btn btn-primary">Закрыть</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "StageSettings",
  data() {
    return {
      stage: {}
    }
  },
  methods: {
    getStageData() {
      APIManager.detail({model: 'stage', id: this.$route.params.stageId}).then(res => this.stage = res)
    },
    updateStage(data) {
      APIManager.update({model: 'stage', id: this.stage.id, data: data})
    }
  },
  created() {
    this.getStageData()
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <h5>{{ headerText }}</h5>
    <p class="text-muted">{{ mutedText }}</p>
    <div>
      <button @click="deleteBoard" class="btn btn-primary btn-sm">{{ deleteText }}</button>
      <button @click="closeModal" class="btn btn-secondary btn-sm ms-2">Отменить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DestroyConfirmationModal",
  props: {
    headerText: String,
    mutedText: String,
    item_id: String,
    deleteText: {
      type: String,
      default: "Удалить"
    }
  },
  methods: {
    deleteBoard() {
      this.$emit('itemDelete', this.item_id)
    },
    closeModal() {
      this.$emit('modalClose')
    }
  }
}
</script>

<style scoped>

</style>
<template>
    <div class="m-3 user-main__wrapper">
        <vue-title :title="`Профиль пользователя ${user.username ? user.username : ''}`"></vue-title>
        <div v-if="!loading" class="user-main">
            <div class="edit-btn d-flex">
                <div class="ms-auto">
                    <button class="btn btn-primary btn-sm edit-profile-btn"
                            @click="isEdit = true"
                            v-if="!isEdit && this.$store.state.user.id === user.id"
                            type="button">Редактировать профиль
                    </button>
                    <button class="btn btn-primary btn-sm edit-profile-btn"
                            v-if="isEdit"
                            @click="endEdit"
                            type="button">Завершить редактирование
                    </button>
                </div>
            </div>
            <div class="header">
                <div class="avatar-section d-inline-flex flex-column align-items-center">
                    <div class="d-inline-block">
                        <div v-if="!isEdit" class="user-avatar rounded-circle me-3" :style="{ 'background-image': `url(${user.avatar})` }" alt="Аватар"></div>
                        <div v-if="isEdit" class="user-avatar rounded-circle me-3"
                             :style="{ 'background-image': `url(${avatarImage ? avatarImage : user.avatar})`}"></div>
                    </div>
                    <div>
                        <div v-if="isEdit" class="mt-2 me-4 d-inline-block">
                            <input @change="updateAvatarSrc" type="file" hidden id="id_avatar">
                            <label class="btn btn-secondary btn-sm" for="id_avatar">Выбрать изображение</label>
                        </div>
                        <div v-if="isAvatarToSave" class="mt-2 d-inline-block ms-2">
                            <button @click="updateAvatar" type="button" class="btn btn-primary btn-sm">Загрузить</button>
                        </div>
                    </div>
                </div>
                <div class="user-info d-inline-block">
                    <div class="username__wrapper">
                        <p class="fw-bold mb-1 username">{{ user.username }}</p>
                    </div>
                    <div class="user-description">
                        <p v-if="!isEdit" class="mb-3">{{ user.description }}</p>
                        <input v-if="isEdit"
                               @change="updateUser({ 'short_about': user.description })"
                               v-model="user.description"
                               class="form-control form-control-sm"
                               placeholder="Коротко о себе"
                               title="Коротко о себе">
                    </div>
                </div>
            </div>
            <hr>
            <div class="user-meta">
                <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                    <div class="meta-key">ID Пользователя</div>
                    <div class="meta-value meta-user-id">{{ user.id }}</div>
                </div>
                <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                    <div class="meta-key">Имя</div>
                    <div v-if="!isEdit" class="meta-value">{{ user.first_name }}</div>
                    <div v-if="isEdit" class="meta-value-edit"><input v-model="user.first_name"
                                                                      @change="updateUser({ 'first_name': user.first_name })"
                                                                      class="form-control form-control-sm"
                                                                      placeholder="Имя"
                                                                      title="Имя"></div>
                </div>
                <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                    <div class="meta-key">Фамилия</div>
                    <div v-if="!isEdit" class="meta-value">{{ user.last_name }}</div>
                    <div v-if="isEdit" class="meta-value-edit"><input v-model="user.last_name"
                                                                      @change="updateUser({ 'last_name': user.last_name })"
                                                                      class="form-control form-control-sm"
                                                                      placeholder="Фамилия"
                                                                      title="Фамилия"></div>
                </div>
                <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                    <div class="meta-key">Email</div>
                    <div v-if="!isEdit" class="meta-value">{{ user.email }}</div>
                    <div v-if="isEdit" class="meta-value-edit"><input v-model="user.email"
                                                                      :readonly="emailReadOnly"
                                                                      @click="emailReadOnly = !emailReadOnly"
                                                                      style="background-color: inherit"
                                                                      @blur="emailReadOnly = true"
                                                                      type="email"
                                                                      autocomplete="off"
                                                                      @change="updateUser({ 'email': user.email })"
                                                                      class="form-control form-control-sm"
                                                                      placeholder="Email"
                                                                      name="email"
                                                                      title="Email"></div>
                </div>
            </div>
            <hr v-if="isEdit">
            <div v-if="isEdit" class="row">
                <div class="col-lg-6 col-md-12 mt-lg-0">
                    <div class="user-access">
                        <div class="meta-item d-flex justify-content-between align-items-center">
                            <div class="meta-key">Открытый профиль</div>
                            <div v-if="isEdit" class="meta-value-edit">
                                <div class="form-check form-switch">
                                    <input title="is_opened"
                                           @change="updateUser({ is_opened: user.is_opened})"
                                           class="form-check-input" type="checkbox" id="id_is_opened"
                                           v-model="user.is_opened">
                                </div>
                            </div>
                        </div>
                        <div class="meta-item d-flex justify-content-between align-items-center">
                            <div class="meta-key">Получать уведомления в телеграм</div>
                            <div v-if="isEdit" class="meta-value-edit">
                                <div class="form-check form-switch">
                                    <input title="receive_telegram_notifications"
                                           @change="updateUser({ rc_telegram_new_task: user.rc_telegram_new_task})"
                                           class="form-check-input" type="checkbox"
                                           id="id_receive_telegram_notifications"
                                           v-model="user.rc_telegram_new_task">
                                </div>
                            </div>
                        </div>
                        <div class="meta-item d-flex justify-content-between align-items-center">
                            <div class="meta-key">Chat ID в Telegram</div>
                            <div v-if="isEdit" class="meta-value-edit">
                                <div class="form-check form-switch">
                                    <input title="telegram_chat_id"
                                           autocomplete="new-password"
                                           placeholder="Telegram Chat ID"
                                           @change="updateUser({ telegram_chat_id: user.telegram_chat_id})"
                                           class="form-control form-control-sm text-end" type="text"
                                           id="id_telegram_chat_id"
                                           v-model="user.telegram_chat_id">
                                </div>
                            </div>
                        </div>
                        <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                            <div class="meta-key">Отображаемое имя</div>
                            <div v-if="isEdit" class="meta-value-edit">
                                <dropdown-select display-name-field="name"
                                                 emit-key="display_name"
                                                 @itemSelected="updateUser"
                                                 :item-list="displayNameChoices"
                                                 lookup-field="value"
                                                 :actual-value="user.display_name"></dropdown-select>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="col-lg-6 col-md-12 mt-lg-0 mt-md-3">
                    <div class="user-access">
                        <div class="meta-item d-flex justify-content-between align-items-center">
                            <div class="meta-key">Старый пароль</div>
                            <div v-if="isEdit" class="meta-value-edit"><input v-model="oldPassword"
                                                                              autocomplete="off"
                                                                              type="password"
                                                                              @change="updateUser({ 'old_password': oldPassword })"
                                                                              class="form-control form-control-sm"
                                                                              placeholder="Старый пароль"
                                                                              title="Старый пароль"></div>
                        </div>
                        <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                            <div class="meta-key">Новый пароль</div>
                            <div v-if="isEdit" class="meta-value-edit"><input v-model="newPassword"
                                                                              autocomplete="off"
                                                                              type="password"
                                                                              @change="updateUser({ 'new_password': newPassword })"
                                                                              class="form-control form-control-sm"
                                                                              placeholder="Новый пароль"
                                                                              title="Новый пароль"></div>
                        </div>
                        <div class="meta-item d-flex justify-content-between align-items-center mt-2">
                            <div class="meta-key">Повторите пароль</div>
                            <div v-if="isEdit" class="meta-value-edit"><input v-model="repeatPassword"
                                                                              autocomplete="off"
                                                                              type="password"
                                                                              @change="updateUser({ 'repeatPassword': repeatPassword })"
                                                                              class="form-control form-control-sm"
                                                                              placeholder="Повторите пароль"
                                                                              title="Повторите пароль"></div>
                        </div>
                        <div class="password-status d-flex mt-3">
                            <p v-if="passwordErrorMessage" class="m-0 password-error-message text-danger">{{ passwordErrorMessage }}</p>
                            <button @click="changePassword" type="button" id="id_change_password" class="btn btn-primary btn-sm ms-auto">
                                <span v-if="isPasswordLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                                <span>Сменить пароль</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="loading d-flex justify-content-center p-5 bg-light shadow-sm rounded-3">
            <div class="spinner-border" role="status"></div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import DropdownSelect from "@/application/components/UIComponents/DropdownSelect";
    import VueTitle from "@/application/components/MiscComponents/VueTitle";

    export default {
        name: "ProfileView",
        components: {VueTitle, DropdownSelect},
        data() {
            return {
                user: {},
                pageTitle: 'Профиль пользователя',
                loading: true,
                isEdit: false,
                oldPassword: '',
                newPassword: '',
                repeatPassword: '',

                isPasswordLoading: false,
                passwordErrorMessage: '',

                displayNameChoices: [
                    { value: 'username', name: 'Имя пользователя' },
                    { value: 'first_name', name: 'Имя' },
                    { value: 'full_name', name: 'Имя и Фамилия' },
                ],
                avatarImage: '',
                isAvatarToSave: false,
                emailReadOnly: true,
            }
        },
        methods: {
            endEdit() {
                this.isEdit = false
                this.isAvatarToSave = false
                this.avatarImage = null
            },
            updateAvatarSrc(event) {
                const image = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(image)
                reader.onload = e => {
                    this.avatarImage = e.target.result
                }
                this.isAvatarToSave = true
            },
            updateAvatar(){
                if (this.avatarImage) {
                    const url = `${this.$store.getters.API_URL}/user/update/image/${this.user.id}`
                    axios.post(url, {
                        image: this.avatarImage,
                    }).then(response => {
                        this.user.avatar = response.data.avatar
                        this.avatarImage = null
                        this.isAvatarToSave = false
                    }).catch(error => console.log(error))
                }
            },
            changePassword() {
                const data = {
                    old_password: this.oldPassword,
                    new_password: this.newPassword,
                    repeat_password: this.repeatPassword
                }
                const id = this.$route.params.id
                const url = `${this.$store.getters.API_URL}/user/password/update/${id}`
                axios.post(url, data).then(response => {
                    console.log(response)
                }).catch(error => {
                    const response = error.response
                    if (response.data.error) {
                        this.passwordErrorMessage = response.data.error
                    }
                })
            },
            handleDescriptionFocus(event) {
                const target = event.target
                this.$nextTick(() => target.select())
            },
            getUserData() {
                const id = this.$route.params.id
                const url = `${this.$store.getters.API_URL}/user/${id}`
                axios.get(url).then(response => {
                    this.user = response.data
                    this.loading = false
                })
            },
            updateUser(data) {
                const id = this.$route.params.id
                const url = `${this.$store.getters.API_URL}/user/update/${id}`
                axios.patch(url, data).then(response => this.user = response.data).catch(error => console.log(error))
            }
        },
        created() {
            this.getUserData()
        },
        watch: {
            $route(to, from) {
                this.getUserData()
            },
        },
    }
</script>

<style scoped>
    #id_telegram_chat_id {
        font-family: "Roboto", sans-serif;
        font-weight: normal;
    }
    .meta-item {
        margin-top: 5px;
    }
    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .user-meta {
        margin-top: 30px;
    }

    .header {
        margin-bottom: 30px;
    }

    .user-avatar {
        width: 210px;
        height: 210px;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .user-info {
        vertical-align: top;
        margin-left: 10px;
        width: 60%;
    }

    .user-description {
        color: #594f98;
        font-weight: bold;
    }

    .meta-key {
        color: #808080;
    }

    .edit-profile-btn {
        font-size: .8em;
    }

    .username {
        font-size: 1.5em;
    }

    h1 {
        margin: 0;
    }

    .user-main {
        padding: 35px;
        background-color: white;
        box-shadow: 0 0 15px rgba(100, 100, 100, .3);
        border-radius: 10px;
    }
    @media (max-width: 951px) {
        .header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .user-info {
            margin-top: 15px;
            margin-left: 0;
            width: 100%;
        }
        .username {
            text-align: center;
        }
        .user-description p {
            text-align: center;
        }
        .edit-profile-btn {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 560px) {
        .meta-user-id {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
            box-sizing: border-box;
            overflow: hidden;
        }
        .meta-value {
            padding-left: 10px;
        }
    }
    @media (max-width: 527px) {
        .user-main__wrapper {
            margin: 0!important;
        }
    }
</style>
<template>
  <div class="task-info-item">
    <div class="info-header me-2">{{ cutString(header, 30) }}:</div>
    <div class="d-flex justify-content-end ms-auto"><slot>{{ value }}</slot></div>
  </div>
</template>

<script>
export default {
  name: "TaskInfoItem",
  props: {
    header: String,
    value: {
      type: [String, Number],
      default: 'Нет'
    }
  }
}
</script>

<style scoped>
.info-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-info-item {
  padding: 3px 0;
  display: flex;
}
</style>
<template>
  <router-link :to="to" v-if="to" :class="['r-button', `btn-${size}`, design, {'square': iconOnly}, `rounded-${rounded}`]">
    <font-awesome-icon :class="[{'me-2': !iconOnly}, 'r-btn-icon']" :icon="`fa-solid fa-${icon}`" v-if="icon"/>
    <span v-if="!iconOnly">{{ text }}</span>
  </router-link>
  <button v-else :class="['r-button', `btn-${size}`, design, {'square': iconOnly}, `rounded-${rounded}`]">
    <font-awesome-icon :class="[{'me-2': !iconOnly}, 'r-btn-icon']" :icon="`fa-solid fa-${icon}`" v-if="icon"/>
    <span v-if="!iconOnly">{{ text }}</span>
  </button>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "RButton",
  components: {FontAwesomeIcon},
  props: {
    loading: Boolean,
    to: {
      type: Object
    },
    text: {
      type: String,
      default: 'Button'
    },
    size: {
      type: String,
      default: 'default'
    },
    icon: String,
    iconOnly: Boolean,
    design: {
      type: String,
      default: 'primary'
    },
    rounded: {
      type: String,
      default: '3'
    }
  },
}
</script>

<style scoped>
.r-button {
  display: inline-block;
  padding: 4px 10px;
  text-align: center;
  user-select: none;
  font-size: 14px;
  transition: background-color .1s ease-in-out;
  box-shadow: none;
  outline: none;
  border: none;
  color: white;
}

.square {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.r-button.primary {
  background-color: var(--primary-color);
}

.r-button.danger {
  background-color: var(--danger-color)
}

.r-button.primary:hover {
  background-color: #4d4487;
}

.r-button.grey {
  background-color: #818181;
}
.r-button.grey:hover {
  background-color: #484848;
}

.r-button.btn-small {
  padding: 4px 8px;
  font-size: 12px;
}

.square.btn-small {
  height: 24px;
  width: 24px;
  font-size: 14px;
}

.square.btn-lg {
  height: 38px;
  width: 38px;
  font-size: 18px;
}

.transparent {
  background-color: transparent;
  color: #2c2c2c;
}

.transparent:hover {
  box-shadow: 0 0 5px rgba(127, 126, 126, 0.3);
}
.outlined {
  outline: 1px solid lightgray;
}
.white {
  background-color: white;
  color: #2c2c2c;
}
.white:hover {
  background-color: #fafafa;
}
.btn-small .r-btn-icon {
  height: 14px;
  width: 14px;
}

</style>
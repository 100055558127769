<template>
  <div class="task-window-component">
    <vue-title :title="title"/>
    <r-breadcrumbs :items="breadcrumbs"/>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="section__wrapper section-height r-scroll shadow-sm">
          <div class="window-main">
            <div class="header mb-3">
              <input title="title" spellcheck="false"
                     @change="changeTask({'title': task.title})"
                     @keydown.enter="$event.target.blur()"
                     class="fw-bold task-name-input ps-2" v-model="task.title">
              <div class="editor">
                <Editor @focusout="changeTask({'description': task.description})"
                        @blur="changeTask({'description': task.description})" v-model="task.description">
                  <template v-slot:toolbar>
                    <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                    </span>
                  </template>
                </Editor>
                <div class="d-flex editor-save very-small text-muted py-1 pe-2">
                  <span class="ms-auto">Сохранено {{ task.modified_at }}</span>
                </div>
              </div>
            </div>
            <div class="task-data-section border mb-3">
              <task-detail-data @taskUpdate="changeTask"
                                :stage-list="stageList"
                                :board-members="boardMembers"
                                :task="task"/>
            </div>
          </div>
          <div class="task-data-section border mb-2">
            <report-table @bulkDelete="onBulkDelete" :report-list="reportList">
              <r-button icon-only
                        icon="plus"
                        size="small"
                        design="grey"
                        :to="{name: 'reportCreate', params: {taskId: this.$route.params.taskId}}"/>
            </report-table>
          </div>
          <task-footer @toggleTaskDelete="showDeleteModal=true"/>
        </div>
      </div>
      <div class="col-12 col-md-4 ps-md-1 mt-2 mt-md-0 section-height auto r-scroll">
        <div class="section__wrapper shadow-sm">
          <div>
            <checkbox-list :task-id="$route.params.taskId"/>
          </div>
        </div>
        <div class="section__wrapper shadow-sm mt-2">
          <div>
            <task-comments @commentCreate="createComment"
                           @deleteComment="deleteComment"
                           :board-members="boardMembers"
                           :comments="task.comments"/>
          </div>
        </div>
      </div>

    </div>
    <transition name="popup">
      <custom-modal @modalClose="showDeleteModal=false" :show-modal-prop="showDeleteModal">
        <destroy-confirmation-modal
            @itemDelete="onItemDelete"
            @modalClose="showDeleteModal=false"
            muted-text="Данные будут безвозвратно уничтожены"
            header-text="Вы уверены что хотите удалить задачу?"/>
      </custom-modal>
    </transition>
    <transition name="popup">
      <view-modal @modalClose="handleCloseModal">
        <router-view @reportCreate="handleCreateReport"
                     @reportUpdate="handleUpdateReport"
                     @modalClose="handleCloseModal" :taskId="task.id"/>
      </view-modal>
    </transition>
  </div>
</template>

<script>
import VueTitle from "@/application/components/MiscComponents/VueTitle";
import {APIManager} from "@/common/helpers/api/manager";
import CustomModal from "@/application/components/UIComponents/CustomModal";
import DestroyConfirmationModal from "@/application/components/UIComponents/DestroyConfirmationModal";
import 'floating-vue/dist/style.css'
import CheckboxList from "@/application/components/ViewComponents/TaskComponents/CheckboxList";
import RBreadcrumbs from "@/application/UIKit/RBreadcrumbs";
import Datepicker from 'vuejs3-datepicker';
import TaskFooter from "@/application/components/ViewComponents/TaskComponents/TaskFooter";
import ReportTable from "@/application/components/ViewComponents/TaskComponents/ReportTable";
import ViewModal from "@/application/components/UIComponents/ViewModal";
import Editor from "primevue/editor";
import TaskDetailData from "@/application/components/ViewComponents/TaskComponents/TaskDetailData";
import TaskComments from "@/application/components/ViewComponents/TaskComponents/TaskComments";
import TabMenu from "primevue/tabmenu";
import RButton from "@/application/UIKit/RButton.vue";

export default {
  name: "TaskWindow",
  components: {
    RButton,
    TabMenu,
    TaskComments,
    TaskDetailData,
    Editor,
    ViewModal,
    ReportTable,
    TaskFooter,
    RBreadcrumbs,
    CheckboxList,
    DestroyConfirmationModal,
    CustomModal,
    VueTitle,
    Datepicker
  },
  data() {
    return {
      showAddReport: true,
      showDeleteModal: false,
      boardMembers: [],
      stageList: [],
      tabItems: [
        {label: 'Dashboard', icon: 'pi pi-home'},
        {label: 'Transactions', icon: 'pi pi-chart-line'},
      ],
      task: {
        title: '',
        description: '',
        responsible_user: {
          id: 0
        },
        priority: 0,
        stage: 0,
        reports: [],
        users: [],
        scheduled_time: 0.0,
        comments: []
      },
      isLoading: true,
      metaLoaded: false,
      editorReady: false,
      isOnTaskEdit: false,
      previewImageSrc: null,
      title: 'Задача'
    }
  },
  methods: {
    onItemDelete() {
      APIManager.destroy({model: 'task', id: this.task.id}).then(res => {
        this.$router.push({name: 'boardDetail', params: {id: this.$route.params.id}})
      })
    },
    onBulkDelete(reportIds) {
      this.task.reports = this.task.reports.filter(rep => !reportIds.includes(rep.id))
    },
    handleUpdateReport(rep) {
      const existingReport = this.task.reports.find(report => report.id === rep.id)
      if (existingReport) {
        Object.assign(existingReport, rep)
      }
      this.handleCloseModal()
    },
    handleCreateReport(report) {
      this.task.reports.push(report)
      this.handleCloseModal()
    },
    handleCloseModal() {
      this.$router.push(`/board/${this.$route.params.id}/task/${this.$route.params.taskId}`)
    },
    onTaskUpdate(task) {
      Object.assign(this.task, task)
    },
    async changeTask(data) {
      await APIManager.update({model: 'task', id: this.$route.params.taskId, data: data}).then(res => {
        this.task = res
      })
    },
    async getTaskData() {
      APIManager.detail({model: 'task', id: this.$route.params.taskId}).then(res => {
        this.task = res
        this.title = res.title
        this.isLoading = false
        if (res.permissions) {
          this.$store.commit('setCurrentPermissions', res.permissions)
        }
      })
      APIManager.getBoardMeta(this.$route.params.id).then(res => {
        this.stageList = res.stages
        this.boardMembers = res.members
        this.metaLoaded = true
      })
    },
    async createComment(text) {
      const data = {
        text: text,
        task_id: this.$route.params.taskId
      }
      APIManager.create({model: 'comment', data}).then(res => {
        this.task.comments[this.task.comments.length] = res
      })
    },
    async deleteComment(id) {
      APIManager.destroy({model: 'comment', id: id}).then(res => {
        this.task.comments = this.task.comments.filter(comment => comment.id !== id);
      })
    },
  },
  created() {
    this.getTaskData();
  },
  computed: {
    breadcrumbs() {
      return [
        {text: "Главная", link: "/"},
        {text: this.task.board_name, link: `/board/${this.$route.params.id}`},
        {text: "Задача", isLast: true},
      ]
    },
    reportList() {
      return this.task.reports.sort((a, b) => {
        const dateA = new Date(a.date_display.split('.').reverse().join('-'));
        const dateB = new Date(b.date_display.split('.').reverse().join('-'));
        return dateA - dateB;
      });
    },
  },
  watch: {
    $route(to, fr) {
      if (to && to.name === 'taskDetail') {
        this.getTaskData()
      }
    },
  }
}
</script>

<style scoped>
.section-height {
  min-height: 85vh;
  max-height: 85vh;
  overflow-y: auto;
}
.section__wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
}

.section-height.auto {
  min-height: auto;
}

.editor-save {
  background-color: #f4f4f4;
}

.task-name-input {
  border: 0;
  width: 100%;
  font-size: 25px;
  color: #0A2647;
  outline: none;
  transition: .2s;
  margin-bottom: 10px;
  padding-bottom: 3px;
}

.task-name-input:hover:not(:focus) {
  background-color: #eeeeee;
  cursor: pointer;
}

.task-name-input:focus {
  cursor: text;
  background-color: #eeeeee;
}

@media (max-width: 767px) {
  .section__wrapper {
    max-height: initial;
    min-height: auto;
  }
}

</style>
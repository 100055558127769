<template>
  <div data-board-id="7" id="create_stage" class="add-stage-card">
    <div id="add-addition-stage" class="task_stage_card__inner">
      <div v-show="!showAdditionStage" class="add-stage-meta" @click="toggleAdditionStage">
        <div class="stage-meta-icon__wrapper">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </div>
        <span class="add-stage-text">Новый этап</span>
      </div>
      <div v-show="showAdditionStage" class="addition-stage-block active">
        <input style="outline: 1px solid #c3c3c3" id="additionInput" ref="addition" title=""
               v-model="newStageName"
               @keydown.enter="createNewStage(board_id)"
               placeholder="Введите название этапа" autocomplete="off"
               class="form-control-c form-control-sm d-block mb-2 w-100">
        <div>
          <button @click="createNewStage(board_id)" type="button" class="btn btn-primary btn-sm me-1">
            Сохранить этап
          </button>
          <button @click="toggleAdditionStage" type="button" class="btn btn-danger btn-sm">Отменить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "CreateNewStage",
  data() {
    return {
      newStageName: '',
      showAdditionStage: false,
    }
  },
  props: {
    board_id: {
      type: String,
      required: true
    },
    stages: {
      type: Array,
    }
  },
  methods: {
    toggleAdditionStage() {
      this.showAdditionStage = !this.showAdditionStage
      if (this.showAdditionStage) {
        this.$emit('additionFocus', this.$refs.addition)
      }
    },
    async createNewStage(id) {
      if (!this.newStageName) {
        return
      }
      const data = {
        board_id: id,
        title: this.newStageName,
        is_last: false,
        order: this.stages.length
      }
      APIManager.create({model: 'stage', data: data}).then(res => {
        this.newStageName = ''
        this.showAdditionStage = false
        this.$emit('createStage', res);
      })
    },
  },

}
</script>

<style scoped>
.add-stage-card {
  display: inline-block;
  height: max-content;
  padding: 2px 6px;
  transition: .2s;
  width: max-content;
  min-width: 230px;
}

.task_stage_card__inner {
  position: relative;
}

.add-stage-meta {
  margin-top: 5px;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 5px;
  border-radius: 16px;
}

.add-stage-meta:hover .stage-meta-icon__wrapper {
  background-color: var(--primary-color);
  color: white;
}

.stage-meta-icon__wrapper {
  font-size: 18px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.add-stage-text {
  font-size: 1em;
  margin-left: 5px;
}

.addition-stage-block {
  font-weight: 600;
}

</style>
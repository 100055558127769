<template>
  <transition-group class="r-toast-container" tag="div" name="fromSide">
    <toast-item :body-text="toast.text"
                :timeout="toast.timeout"
                :key="toast.key"
                @remove="onRemove(index)"
                v-for="(toast, index) in $store.getters.TOAST_LIST"/>
  </transition-group>
</template>

<script>
import ToastItem from "@/application/components/ViewComponents/ToastComponents/ToastItem";

export default {
  name: "ToastList",
  components: {ToastItem},
  methods: {
    onRemove(index) {
      this.$store.commit('removeToast', index)
    }
  }
}
</script>

<style scoped>
.r-toast-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 7000;
}
</style>
export const HelperMixin = {
    methods: {
        cutString(str, short_by) {
            return str && str.length > short_by ? str.substring(0, short_by) + '...' : str
        },
        formatDate(payload) {
            let date
            let formatted
            if (typeof (payload) === 'string') {
                if (payload.includes('.')) {
                    date = Date.parse(this.dotToDashInverse(payload))
                } else {
                    date = Date.parse(payload)
                }
            } else {
                date = payload
            }
            if (date) {
                if (typeof (date) === 'number') {
                    date = new Date(date)
                }
                formatted = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            }
            return formatted
        },
        dotToDashInverse(str) {
            return str.split('.').reverse().join('-')
        },
        getPermission() {
            const userId = this.$store.state.user.id
            if (userId) {
                return this.$store.state.currentPermissions.find(perm => perm.user.id === userId)
            }
        },
        userHasPermission(permissionName, allow = false) {
            const permission = this.getPermission()
            if (permission) {
                return permission[permissionName]
            }
            return allow
        },
        number_as_verbose_string(number, forms) {
            let n = Math.abs(number) % 100;
            let n1 = n % 10;
            if (n > 10 && n < 20) {
                return `${forms[2]}`;
            }
            if (n1 > 1 && n1 < 5) {
                return `${forms[1]}`;
            }
            if (n1 === 1) {
                return `${forms[0]}`;
            }
            return `${forms[2]}`;
        },
        time_verbose(hours, minutes) {
            const h = ['час', 'часа', 'часов']
            const m = ['минута', 'минуты', 'минут']
            const verbose_array = []
            if (hours) {
                verbose_array.push(`${hours} ${this.number_as_verbose_string(hours, h)}`)
            }
            if (minutes) {
                verbose_array.push(`${minutes} ${this.number_as_verbose_string(minutes, m)}`)
            }
            return verbose_array.join(' ')
        },
        timeAgo(lastUpdated) {
            const now = new Date();
            const diff = Math.floor((now - new Date(lastUpdated)) / 1000);

            if (diff === 0) {
                return "только что"
            }
            const secondsArray = ["секунду", "секунды", "секунд"]
            const minutesArray = ['минуту', 'минуты', 'минут']
            const hoursArray = ['час', 'часа', 'часов']
            const daysArray = ["день", "дня", "дней"]

            let words

            if (diff < 60) {
                words = `${diff} ${this.number_as_verbose_string(diff, secondsArray)}`
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                words = `${minutes} ${this.number_as_verbose_string(minutes, minutesArray)}`
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                words = `${hours} ${this.number_as_verbose_string(hours, hoursArray)}`
            } else {
                const days = Math.floor(diff / 86400);
                words = `${days} ${this.number_as_verbose_string(days, daysArray)}`
            }
            return `${words} назад`
        }
    }
}
<template>
  <div :data-id="stage.id" class="stage-card">
    <div class="stage-name d-flex justify-content-between align-items-center">
      <input spellcheck="false"
             @change="updateStage(stage)"
             class="stage-name-input"
             type="text" title=""
             v-model="stage.title">
      <div class="stage-menu">
        <div @click="enableAdditionTask" class="stage-menu-child">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </div>
      </div>
      <div>
        <div @click.stop.prevent="toggleStageMenu" class="stage-menu">
          <div class="stage-menu-child">
            <font-awesome-icon icon="fa-solid fa-ellipsis"/>
          </div>
        </div>
        <Menu class="position-absolute" ref="menu" id="overlay_menu" :model="stageMenuItems" :popup="true"/>
      </div>
    </div>
    <task-create-card @closeCard="isOnAdditionTask=false" :stage-id="stage.id" @createTask="createNewTask" class="mb-2"
                      v-if="isOnAdditionTask"/>
    <div>
      <Container :getChildPayload="getChildPayload"
                 @drop="onDrop"
                 :non-drag-area-selector="'.task-archive'"
                 :dropPlaceholder="true"
                 groupName="tasks">
        <Draggable v-for="task in taskList">
          <task-item :key="task.id"
                     @taskUpdate="onTaskUpdate"
                     @taskDelete="onTaskDelete"
                     :data-id="task.id"
                     class="my-1 task-draggable"
                     :task="task"/>
        </Draggable>
      </Container>
    </div>

  </div>
</template>

<script>
import TaskItem from "@/application/components/ViewComponents/TaskComponents/TaskItem";
import {Container, Draggable} from "vue3-smooth-dnd"
import {APIManager} from "@/common/helpers/api/manager";
import Menu from 'primevue/menu'
import TaskCreateCard from "@/application/components/ViewComponents/TaskComponents/TaskCreateCard";
import InputText from "primevue/inputtext";

export default {
  name: "StageCard",
  components: {TaskCreateCard, TaskItem, Container, Draggable, Menu, InputText},
  props: {
    stage: {
      type: Object,
      default: {}
    },
    stageCount: {type: Number, default: 0},
    showArchived: Boolean
  },
  data() {
    return {
      isMenuActive: false,
      additionTaskName: '',
      additionTaskDesc: '',
      isOnAdditionTask: false,
      useOffset: false,
      stageMenuItems: [
        {
          label: 'Настройки',
          icon: 'pi pi-cog',
          command: () => {
            this.$router.push({name: 'stageSettings', params: {stageId: this.stage.id}})
          }
        },
        {
          label: 'Удалить',
          icon: 'pi pi-trash',
          command: () => {
            this.$router.push({name: 'stageDelete', params: {id: this.$route.params.id, stageId: this.stage.id}})
          },
        },
        {
          label: 'Архивировать задачи',
          icon: 'pi pi-box',
          command: () => {
            this.bulkArchive()
          }
        },
        {
          label: 'Влево',
          icon: 'pi pi-arrow-left',
          command: () => {
            let oldOrder = this.stage.order
            let order = 0
            if (oldOrder === order) {
              return
            }
            if (this.stage.order - 1 >= 0) {
              order = this.stage.order - 1
            }
            this.patchStage(this.stage.id, {order: order})
            this.$emit('swap', {id: this.stage.id, order: order, oldOrder: oldOrder})
          }
        },
        {
          label: 'Вправо',
          icon: 'pi pi-arrow-right',
          command: () => {
            let oldOrder = this.stage.order
            let order = this.stageCount
            if (oldOrder === order) {
              return
            }
            if (this.stage.order + 1 <= this.stageCount) {
              order = this.stage.order + 1
            }
            this.patchStage(this.stage.id, {order: order})
            this.$emit('swap', {id: this.stage.id, order: order, oldOrder: oldOrder})
          }
        }
      ]
    }
  },
  methods: {
    toggleStageMenu(event) {
      this.$refs.menu.toggle(event);
    },
    onTaskUpdate(task) {
      this.$emit('taskUpdate', task)
    },
    onTaskDelete(task) {
      this.$emit('taskDelete', task)
    },
    getChildPayload(index) {
      return this.stage.tasks[index]
    },
    cleanBodyFromDndClassList() {
      document.body.className = document.body.className
          .split(' ')
          .filter(c => c.indexOf('dnd') < 0)
          .join(' ')
    },
    onDrop(dropResult) {
      this.cleanBodyFromDndClassList()
      const items = this.applyDrag(this.stage.tasks, dropResult)
      this.$emit('taskDrop', items, this.stage.id)
    },
    applyDrag(arr, dragResult) {
      const {removedIndex, addedIndex, payload} = dragResult;
      if (removedIndex === addedIndex) return arr;

      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
        APIManager.update({
          model: 'task', id: itemToAdd.id, data: {
            stage_id: this.stage.id,
            order: addedIndex
          }
        }).then(response => {
          this.$emit('taskMove', response)
        })
      }
      return result;
    },
    enableAdditionTask() {
      this.isOnAdditionTask = true
    },
    bulkArchive() {
      const query = new URLSearchParams(window.location.search);
      APIManager.bulkArchive(this.stage.id, query.get('archive') === 'true').then(res => {
        this.$emit('bulkArchive', res, this.stage.id)
      })
    },
    patchStage(id, data) {
      APIManager.update({model: 'stage', id: id, data: data})
    },
    updateStage(stage) {
      this.patchStage(stage.id, {title: stage.title})
    },
    createNewTask(data) {
      APIManager.create({model: 'task', data: data}).then(res => {
        this.$emit('taskCreated', res)
        this.isOnAdditionTask = false
      })
    }
  },
  created() {
    this.useOffset = !this.taskList.length
  },
  computed: {
    taskList: {
      get() {
        if (this.stage.tasks) {
          if (this.showArchived) {
            return this.stage.tasks
          }
          return this.stage.tasks.filter((task) => !task.archive)
        }
        return []
      }
    },
  }
}
</script>

<style scoped>

.stage-card {
  padding: 10px;
  position: relative;
  min-height: 175px;
}

.stage-name {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid #c5c4d2;
}

.stage-name-input {
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  background: transparent;
  border-radius: 16px;
  padding: 5px 10px;
  display: block;
  color: #191d3e;
  transition: background-color .2s;
}

.stage-name-input {
  width: 70%;
}

.stage-name-input:hover:not(:disabled) {
  cursor: pointer;
}

.stage-name-input:hover:not(:focus) {
  background-color: white;
}

.stage-name-input:focus:not(:disabled) {
  background-color: white;
  outline: 2px #cfd5dd solid;
  cursor: text;
}

.stage-name-input:disabled, .stage-name-input:hover:disabled {
  background: transparent;
  border: none;
}

.stage-menu {
  position: relative;
}

.stage-menu-child {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  border-radius: 6px;
  transition: .2s;
  cursor: pointer;
  padding: 5px;
  height: 35px;
  width: 35px;
  color: rgba(82, 81, 117, 1);
  background-color: white;
  font-size: 18px;
}

.stage-menu-child:hover {
  box-shadow: 0 0 15px rgba(128, 128, 128, 0.25);
}

.stage-menu-item > a {
  color: #414141;
  text-decoration: none;
}
</style>
<template>
  <div class="task-create-card">
    <div class="d-flex flex-column">
      <form class="form-inside task-create-form" @submit.prevent="createTask">
        <InputText v-model="name" placeholder="Название" class="w-100 mb-1"/>
        <textarea name="description"
                  v-model="description"
                  @input="onDescriptionInput"
                  spellcheck="false"
                  class="w-100 description"
                  placeholder="Описание"></textarea>
        <div class="doc-wrapper">
          <div class="d-flex mt-2 task-create-doc">
            <div class="ms-auto d-flex">
              <r-button type="button" @click="closeCard" icon="xmark" design="danger" :icon-only="true"/>
              <r-button @click="createTask" class="ms-1" icon="check" :icon-only="true"/>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import RButton from "@/application/UIKit/RButton";

export default {
  name: "TaskCreateCard",
  components: {RButton, InputText, Card},
  props: {
    stageId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      name: "",
      description: ""
    }
  },
  methods: {
    onDescriptionInput(event) {
      const element = event.target
      if (element.scrollHeight > 60) {
        element.style.height = (element.scrollHeight) + "px";
      }
    },
    closeCard() {
      this.$emit('closeCard')
    },
    createTask() {
      if (this.name.length) {
        this.$emit('createTask', {title: this.name, description: this.description, stage_id: this.stageId, order: 0})
        this.name = ''
        this.description = ''
      }
    }
  }
}
</script>

<style scoped>
.task-create-card {
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(111, 110, 110, 0.3);
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

.task-create-form {
  min-height: 160px;
  border: none;
}

.description {
  height: 50px;
  overflow: hidden;
}
.doc-wrapper {
  min-height: 35px
}
.task-create-doc {
  position: absolute;
  right: 8px;
  bottom: 8px
}
</style>
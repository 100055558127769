<template>
  <div class="report-create-component">
    <form @submit.prevent="formSubmit">
      <div class="header">
        <h1 class="h4 mb-3 fw-bold">Создание табеля</h1>
      </div>
      <div>
        <div class="group mb-3">
          <div class="fw-bold mb-1">Дата</div>
          <div>
            <datepicker :full-month-name="true"
                        :input-class="['report-date-input', datepickerClass]"
                        class="report-date"
                        monday-first
                        language="ru"
                        :clear-button="true"
                        v-model="reportDate"
                        placeholder="Выберите дату"
                        :typeable="false"
                        :hideInput="false"/>
          </div>
        </div>
        <div class="group mb-3">
          <div class="fw-bold mb-1">Описание</div>
          <div>
            <textarea v-model="description" required placeholder="Введите описание"
                      class="form-control no-resize textarea-default-size"></textarea>
          </div>
        </div>
        <div class="group mb-3">
          <div class="fw-bold mb-1">Время</div>
          <div class="row">
            <div class="col-6">
              <input v-model="hours"
                     min="0"
                     :class="{'is-invalid': this.hoursInvalid}"
                     @focus="onTimeFocus"
                     required
                     type="number"
                     placeholder="Часы"
                     class="form-control time-input">
            </div>
            <div class="col-6">
              <input v-model="minutes"
                     min="0"
                     max="59"
                     :class="{'is-invalid': this.minutesInvalid}"
                     @focus="onTimeFocus"
                     @focusout="onTimeFocusOut"
                     required
                     type="number"
                     placeholder="Минуты"
                     class="form-control time-input">
            </div>
          </div>
        </div>
        <div class="group">
          <div>
            <button type="submit" class="d-block btn btn-primary btn-sm mb-2 w-100">Сохранить</button>
            <button @click="closeModal" type="button" class="d-block btn btn-danger btn-sm w-100">Отменить</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "ReportCreate",
  components: {
    Datepicker
  },
  props: {
    taskId: String
  },
  data() {
    return {
      id: null,
      reportDate: new Date(),
      hours: 0,
      minutes: 0,
      maxMin: 59,
      description: '/',
      hoursInvalid: false,
      minutesInvalid: false,
      dateInvalid: false
    }
  },
  methods: {
    formSubmit() {
      this.hoursInvalid = false
      this.minutesInvalid = false
      this.dateInvalid = false

      let isValid = true
      if (this.hours <= 0 && this.minutes <= 0) {
        this.minutesInvalid = true
        this.hoursInvalid = true
        isValid = false
      }
      if (!this.reportDate) {
        this.dateInvalid = true
        isValid = false
      }
      if (!isValid) {
        return
      }

      const data = {
        task_id: this.taskId,
        time_hours: this.hours,
        time_minutes: this.minutes,
        description: this.description,
        date: this.formatDate(this.reportDate)
      }
      if (this.id) {
        APIManager.update({model: 'report', id: this.id, data: data}).then(res => {
          this.$emit('reportUpdate', res)
        })
      } else {
        APIManager.create({model: 'report', data: data}).then(res => {
          this.$emit("reportCreate", res)
        })
      }

    },
    onTimeFocus($event) {
      $event.target.select()
    },
    onTimeFocusOut() {
      if (this.minutes <= 0) {
        this.minutes = 0
      } else if (this.minutes > this.maxMin) {
        this.minutes = this.maxMin
      }
    },
    closeModal() {
      this.$emit('modalClose')
    },
    getReportData() {
      APIManager.detail({model: 'report', id: this.$route.params.repId}).then(res => {
        this.id = res.id
        this.hours = res.time_hours
        this.minutes = res.time_minutes
        this.description = res.description
        this.reportDate = res.date_raw
      })
    },
  },
  created() {
    if (this.$route.params.repId) {
      this.getReportData()
    }
  },
  computed: {
    datepickerClass() {
      let defaultClass = 'r-datepicker'
      if (this.dateInvalid) {
        defaultClass += ' invalid-date'
      }
      return defaultClass
    }
  }
}
</script>

<style scoped>
.time-input:hover {
  cursor: pointer;
}
.time-input:focus {
  cursor: text;
}
</style>
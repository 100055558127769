<template>
  <div class="app-loader">
    <div class="loader-content__wrapper">
      <div class="loader-content">
        <img class="loader-img" alt="loader image" src="/resolve.webp"/>
        <div class="spinner-border sp mt-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader"
}
</script>

<style scoped>
.app-loader {
  height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: #f4f4f4;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-img {
  height: 75px;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sp {
  height: 25px;
  width: 25px;
  color: #484848;
}
</style>
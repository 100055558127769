<template>
  <div class="task-root" @contextmenu="openTaskContextMenu">
    <ContextMenu ref="taskContextMenu" :model="taskMenuItemList"/>
    <router-link draggable="false"
                 tag="div"
                 :class="{'is-done': task.completed_at, 'task-archive': task.archive}"
                 :to="routeOptions"
                 class="task__wrapper">

      <div class="task__inner">
        <div class="task">
          <transition name="fromSideY">
            <TaskInfo v-if="showTaskInfo"
                      :completed-checkboxes="completedCheckboxes"
                      :all-checkboxes="allCheckboxes"
                      :priority-value="priorityValue"
                      :task="task"/>
          </transition>
          <div class="task-name d-flex justify-content-between">
            <span class="task-name-text fw-bold">{{ task.title }}</span>
            <div @mouseenter="onTaskInfo" @mouseleave="onTaskInfoOut" class="info-icon">
              <font-awesome-icon :icon="['fas', 'info']"/>
            </div>
          </div>
          <div class="task-description mt-1">
            <p class="text-muted small mb-0 text-break">
              {{ cutString(this.cleanDescription, 120) }}
            </p>
          </div>
          <div class="task-info d-flex mt-1">
            <div class="d-flex align-items-center">
              <div class="fs-8 d-flex align-items-center"
                   :class="getPriorityClassName(task.priority)">
                <div class="dot">
                  <font-awesome-icon icon="fa-solid fa-flag" class="mt-1 very-small"/>
                </div>
              </div>
              <div v-if="task.responsible_user"
                   class="fs-8 res-user d-flex align-items-center ms-2 user-badge">
                <div class="user-img">
                  <img height="18" width="18" class="rounded-circle"
                       :src="task.responsible_user.avatar"
                       alt="avatar">
                </div>
                <div class="user-name ms-1">
                  <span class="text-secondary very-small">{{ task.responsible_user.username }}</span>
                </div>
              </div>
            </div>
            <div class="ms-auto d-flex align-items-center">
              <div v-if="allCheckboxes > 0 || completedCheckboxes > 0" class="me-2">
                <span class="comments-text small text-secondary">{{ completedCheckboxes }}/{{ allCheckboxes }}</span>
              </div>
              <div v-if="task.completed_at" class="is-done-marker text-light rounded rounded-circle bg-success me-1">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </div>
              <div v-if="commentsCount > 0" class="comments ms-1">
                <font-awesome-icon icon="fa-solid fa-comments" class="text-secondary me-1"/>
                <span class="comments-text small text-secondary">{{ commentsCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="progressStyle.width !== '0%'" class="px-2">
        <div class="progress">
          <div class="progress-bar" :style="progressStyle"></div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import RMenu from "@/application/UIKit/RMenu";
import {APIManager} from "@/common/helpers/api/manager";
import RButton from "@/application/UIKit/RButton";
import ContextMenu from 'primevue/contextmenu'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TaskInfo from "@/application/components/ViewComponents/TaskComponents/TaskInfo";

export default {
  name: "TaskItem",
  components: {TaskInfo, FontAwesomeIcon, RButton, RMenu, ContextMenu},
  props: {
    task: {
      type: Object
    },
  },
  data() {
    return {
      priorityOptions: [
        {value: 0, name: 'Низкий', classname: 'text-secondary'},
        {value: 1, name: 'Средний', classname: 'text-info'},
        {value: 2, name: 'Высокий', classname: 'text-warning'},
        {value: 3, name: 'Критический', classname: 'text-danger'},
      ],
      showMenu: false,
      taskMenuItems: [
        {
          id: 1,
          label: 'Очистить выполнение',
          icon: 'pi pi-undo',
          invisible: !this.task.completed_at,
          command: this.markAsUnfulfilled
        },
        {
          id: 2,
          label: 'Готово',
          icon: 'pi pi-check-square',
          invisible: !!this.task.completed_at,
          command: this.markAsFulfilled
        },
        {
          id: 3,
          label: 'В архив',
          icon: 'pi pi-box',
          invisible: this.task.archive,
          command: this.markAsArchived
        },
        {
          id: 4,
          label: 'Разархивировать',
          icon: 'pi pi-folder-open',
          invisible: !this.task.archive,
          command: this.markAsUnarchived
        },
        {
          id: 5,
          icon: 'pi pi-trash',
          label: 'Удалить',
          command: this.deleteTask
        },
      ],
      showTaskInfo: false
    }
  },
  watch: {
    'task.completed_at'() {
      this.setCleanVisibility(this.task)
    },
  },
  methods: {
    onTaskInfoOut() {
      this.showTaskInfo = false
    },
    onTaskInfo() {
      this.showTaskInfo = true
    },
    setCleanVisibility(task) {
      this.taskMenuItems[0].invisible = !task.completed_at
      this.taskMenuItems[1].invisible = !!task.completed_at
      this.taskMenuItems[2].invisible = task.archive
      this.taskMenuItems[3].invisible = !task.archive
    },
    openTaskContextMenu(event) {
      this.$refs.taskContextMenu.show(event)
    },
    markAsUnfulfilled() {
      APIManager.update({model: 'task', id: this.task.id, data: {completed_at: null}}).then(res => {
        this.setCleanVisibility(res)
        this.$emit('taskUpdate', res)
      })
    },
    markAsFulfilled() {
      APIManager.update({model: 'task', id: this.task.id, data: {completed_at: new Date()}}).then(res => {
        this.setCleanVisibility(res)
        this.$emit('taskUpdate', res)
      })
    },
    markAsArchived() {
      APIManager.update({model: 'task', id: this.task.id, data: {archive: true}}).then(res => {
        this.setCleanVisibility(res)
        this.$emit('taskUpdate', res)
      })
    },
    markAsUnarchived() {
      APIManager.update({model: 'task', id: this.task.id, data: {archive: false}}).then(res => {
        this.setCleanVisibility(res)
        this.$emit('taskUpdate', res)
      })
    },
    deleteTask() {
      this.$router.push({name: 'taskDelete', params: {id: this.task.board_id, taskId: this.task.id}})
    },
    getPriorityClassName() {
      let options = this.priorityOptions.filter(pr => pr.value === this.task.priority)
      if (options.length) {
        return options[0].classname
      }
      return null
    },
  },
  computed: {
    priorityValue() {
      let options = this.priorityOptions.filter(pr => pr.value === this.task.priority)
      if (options.length) {
        return options[0].name
      }
      return null
    },
    allCheckboxes() {
      if (this.task) {
        return this.task.checkboxes ? this.task.checkboxes.all : 0
      }
    },
    completedCheckboxes() {
      if (this.task) {
        return this.task.checkboxes ? this.task.checkboxes.completed : 0
      }
    },
    commentsCount() {
      if (this.task) {
        return this.task.comments_count ? this.task.comments_count : 0
      }
    },
    progressStyle() {
      const progress = this.task.completion ? this.task.completion.completion : 0
      const st = {
        width: `${progress}%`
      }
      if (progress > 95 && progress < 105) {
        st['background-color'] = 'var(--bs-green)'
      } else if (progress > 105 && progress < 120) {
        st['background-color'] = 'var(--bs-yellow)'
      } else if (progress >= 120) {
        st['background-color'] = 'var(--danger-color)'
      }
      return st
    },
    cleanDescription() {
      return this.task.description ? this.task.description.replace('&nbsp', ' ').replace(/<\/?[^>]+(>|$)/g, "") : ''
    },
    taskMenuItemList() {
      return this.taskMenuItems.filter(i => !i.invisible)
    },
    routeOptions() {
      return {
        name: 'taskDetail', params: {taskId: this.task.id, id: this.task.board_id}
      }
    }
  },
}
</script>

<style scoped>
.task {
  position: relative;
}

.info-icon {
  position: relative;
  font-size: 14px;
  color: #414141;
  padding: 0 7px;
  z-index: 1000;
  height: fit-content;
}

.info-icon:hover {
  color: #0a53be;
}

.is-done-marker {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
}

.progress-bar {
  background-color: var(--bs-indigo);
}

.progress {
  background-color: transparent;
  height: 3px;
}

.user-badge {
  pointer-events: none;
}

.fs-7 {
  font-size: .7em;
}

.fs-8 {
  font-size: .8em;
}

.task__inner {
  padding: 13px;
}

.task__wrapper {
  display: block;
  text-decoration: none;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px #dbdbdb;
}

.task__wrapper:hover:not(.task-archive) {
  outline: 1px solid #bbbed4;
}

.task__wrapper.is-done {

}

.task-name-text {
  color: #2f3669;
  font-size: 14px;
}

.task-archive {
  background-color: #eaecf1;
}

.task-root {
  z-index: 1200;
}
</style>
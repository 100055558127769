<template>
  <div class="dashboard-root">
    <div class="mb-5">
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <h1 class="h2 fw-bold mb-0">Дашборд</h1>
          <div class="ms-auto d-flex flex-column text-muted">
            <p class="mb-0 small">{{ lastUpdated }}</p>
            <div @click="refreshDashboard"
                 class="d-flex align-items-center mt-1 ms-auto clickable refresh-dashboard-btn">
              <font-awesome-icon :class="{updating: isOnUpdate}" icon="fas fa-sync-alt"/>
              <span class="ms-2">Обновить</span>
            </div>
          </div>
        </div>
        <p class="text-muted">Краткая сводка о бюджете проектов</p>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 mb-2 mb-md-0">
              <DashboardItemNumber background="blue" money color="primary" :value="totalBudgetData.total"
                                   header="Общий бюджет проектов"/>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <DashboardItemNumber background="blue" money color="sky" :value="totalBudgetData.active"
                                   header="Бюджет активных проектов"/>
            </div>
            <div class="col-12 col-lg-4 mt-2 mt-lg-0">
              <DashboardItemNumber background="blue" money :value="totalBudgetData.archive"
                                   header="Бюджет архивированных проектов"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2 mt-md-0">
          <div class="row">
            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
              <DashboardItemNumber :value="tasks.all" header="Всего задач"/>
            </div>
            <div class="col-12 col-lg-6">
              <DashboardItemNumber :value="tasks.completed" header="Задач завершено"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
              <DashboardItemNumber :value="projectSummary.total" header="Всего проектов"/>
            </div>
            <div class="col-12 col-lg-6">
              <DashboardItemNumber :value="projectSummary.archive" header="Проектов в архиве"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 mt-2 mt-md-0">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 mb-2 mb-md-0">
              <DashboardItemNumber money
                                   background="green"
                                   color="primary"
                                   :value="realBudgetData.total"
                                   header="Фактический бюджет проектов"/>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-2 mb-md-0">
              <DashboardItemNumber money
                                   background="green"
                                   color="sky"
                                   :value="realBudgetData.active"
                                   header="Фактический бюджет активных проектов"/>
            </div>
            <div class="col-12 col-lg-4 mt-2 mt-lg-0">
              <DashboardItemNumber money
                                   background="green"
                                   :value="realBudgetData.archive"
                                   header="Фактический бюджет проектов в архиве"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <h1 class="h2 fw-bold mb-0">Проекты</h1>
          <r-button @click="onProjectsBtnClick" icon="plus" icon-only class="ms-2"/>
        </div>
        <p class="text-muted">Краткое описание всех проектов</p>
      </div>
      <div v-if="projectList.length" class="project-list table-responsive">
        <table class="table dashboard-table">
          <thead>
          <tr>
            <th>Название проекта</th>
            <th>Участники</th>
            <th>Дата создания</th>
            <th>Бюджет проекта</th>
            <th>Реальный бюджет</th>
            <th>Задачи</th>
          </tr>
          </thead>
          <tbody>
          <DashboardTableRow :project="project" v-for="project in projectList"/>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="fw-bold fs-3 text-center">Проектов нет</p>
        <p class="text-muted text-center mb-0">Добавьте новый проект</p>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardItemNumber from "@/application/components/Dashboard/DashboardItemNumber.vue";
import UserAvatarList from "@/application/UIKit/UserAvatarList.vue";
import RButton from "@/application/UIKit/RButton.vue";
import DashboardTableRow from "@/application/components/Dashboard/DashboardTableRow.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "Dashboard",
  components: {FontAwesomeIcon, DashboardTableRow, RButton, UserAvatarList, DashboardItemNumber},
  data() {
    return {
      isOnUpdate: false
    }
  },
  methods: {
    async refreshDashboard() {
      this.isOnUpdate = true
      await this.$store.dispatch("getDashboard")
      this.$store.commit("addToast", {"text": "Данные обновлены"})
      this.isOnUpdate = false
    },
    onProjectsBtnClick() {
      this.$emit("createBoard")
    }
  },
  computed: {
    lastUpdated() {
      return "Обновлено " + this.timeAgo(this.$store.state.dashboardUpdated)
    },
    projectList() {
      return this.$store.state.dashboard.project_list
    },
    totalBudgetData() {
      return this.$store.state.dashboard.total_budget
    },
    realBudgetData() {
      return this.$store.state.dashboard.total_real_budget
    },
    tasks() {
      return this.$store.state.dashboard.tasks
    },
    projectSummary() {
      return this.$store.state.dashboard.projects
    },
  },
}
</script>
<style>
.refresh-dashboard-btn:hover {
  color: var(--color-text-black)
}

.updating {
  animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform:rotate(360deg);
    }
}
</style>
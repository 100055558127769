<template>
  <div class="email-confirm flex-column">
    <div class="image">
      <img class="welcome-image" alt="logo" src="/logo.webp">
    </div>
    <div class="welcome">
      <h1 class="fw-bold text-center">Добро пожаловать</h1>
      <p class="text-muted text-center">Необходимо подтвердить адрес электронной почты. Для этого нажмите кнопку ниже</p>
    </div>
    <div class="confirm-window mt-3">
      <form id="confirm_form" method="post">
        <button type="button"
                @click="confirmAddr"
                class="btn btn-primary btn-sm"
                value="Подтвердить">Подтвердить адрес электронной почты
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConfirmationVIew",
  data() {
    return {
      token: this.getParam('token'),
      uid: this.getParam('uid')
    }
  },
  methods: {
    getParam(param) {
      const query = new URLSearchParams(window.location.search);
      return query.get(param)
    },
    confirmAddr() {
      const url = this.$store.getters.CONFIRMATION_URL
      const data = {
        uid: this.uid,
        token: this.token
      }
      axios.post(url, data).then(res => {
        this.$store.commit('addToast', {text: "Адрес электронной почты успешно подтвержден"})
        this.$router.push('/auth/login')
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.email-confirm {
  height: 100svh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome-image {
  height: 100px;
  margin: 25px 0;
}
</style>
<template>
  <destroy-confirmation-modal
      @itemDelete="onBoardDelete"
      header-text="Вы уверены что хотите удалить проект?"
      muted-text="Данные будут безвозвратно уничтожены"/>
</template>

<script>
import DestroyConfirmationModal from "@/application/components/UIComponents/DestroyConfirmationModal";
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "BoardDeleteView",
  components: {DestroyConfirmationModal},
  props: {
    boardId: String,
    callback: Function,
  },
  methods: {
    onBoardDelete(event) {
      const boardId = this.boardId || this.$route.params.id
      APIManager.destroy({model: 'board', id: boardId}).then(res => {
        this.$store.commit("removeBoardFromList", boardId)
        if (this.callback) {
          this.callback()
        } else {
          this.$router.push({name: 'main'})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
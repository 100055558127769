<template>
  <div>
    <div>
      <p class="fw-bold fs-6 m-0">Детали</p>
    </div>
    <div class="detail-table">
      <div class="row mt-3 mb-2">
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <p class="m-0 fw-bold my-2 my-sm-0">Ответственный</p>
            </div>
            <div class="col-12 col-sm-6">
              <Dropdown @update:modelValue="handleDropdownSelected('responsible_user_id', $event)"
                        optionValue="id"
                        class="ms-auto w-100"
                        :modelValue="task.responsible_user.id"
                        optionLabel="username"
                        :options="boardMembers"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <p class="m-0 fw-bold my-2 my-sm-0">Приоритет</p>
            </div>
            <div class="col-12 col-sm-6">
              <Dropdown optionLabel="name"
                        class="ms-auto w-100"
                        :modelValue="task.priority"
                        optionValue="value"
                        :options="priorityOptions"
                        @update:modelValue="handleDropdownSelected('priority', $event)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <p class="m-0 fw-bold my-2 my-sm-0">Этап</p>
            </div>
            <div class="col-12 col-sm-6">
              <Dropdown optionLabel="title"
                        :options="stageList"
                        class="ms-auto w-100"
                        @update:modelValue="handleDropdownSelected('stage_id', $event)"
                        :modelValue="task.stage"
                        optionValue="id"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <p class="m-0 fw-bold my-2 my-sm-0">Дата завершения</p>
            </div>
            <div class="col-12 col-sm-6">
              <datepicker :full-month-name="true"
                          :input-class="'report-date-input'"
                          class="report-date w-100"
                          language="ru"
                          monday-first
                          @update:model-value="updateDate"
                          :clear-button="false"
                          v-model="task.date_deadline"
                          placeholder="Дата завершения"
                          :typeable="false"
                          :hideInput="false"/>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-item row pt-2 mt-1">
        <div class="col-12 col-sm-6 d-flex align-items-center">
          <p class="m-0 fw-bold my-2 my-sm-0">Запланировано часов</p>
        </div>
        <div class="col-12 col-sm-6 py-1 pe-3">
          <div class="d-flex align-items-center">
            <planned-hours @taskUpdate="changeTask"
                           :task-id="task.id"
                           class="ms-auto"
                           :hours="task.scheduled_hours"
                           :minutes="task.scheduled_minutes"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Dropdown from "primevue/dropdown";
import Calendar from 'primevue/calendar'
import PlannedHours from "@/application/components/ViewComponents/TaskComponents/PlannedHours";
import Datepicker from 'vuejs3-datepicker';


export default {
  name: "TaskDetailData",
  components: {
    Calendar,
    InputText,
    Dropdown,
    PlannedHours,
    Datepicker,
  },
  props: {
    task: {
      type: Object,
      default: {}
    },
    boardMembers: {
      type: Array,
      required: true,
      default: []
    },
    stageList: {
      type: Array,
      required: true,
      default: []
    }
  },
  data() {
    return {
      priorityOptions: [
        {value: 0, name: 'Низкий', classname: 'circle-secondary'},
        {value: 1, name: 'Средний', classname: 'circle-info'},
        {value: 2, name: 'Высокий', classname: 'circle-warning'},
        {value: 3, name: 'Критический', classname: 'circle-danger'},
      ],
    }
  },
  methods: {
    updateDate(payload) {
      this.changeTask({date_deadline: this.formatDate(payload)})
    },
    handleDropdownSelected(key, value) {
      let data = {}
      data[key] = value
      if (key === 'stage_id') {
        data.order = 0
      }
      this.changeTask(data)
    },
    changeTask(data) {
      this.$emit('taskUpdate', data)
    }
  }
}
</script>

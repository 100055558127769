<template>
  <div class="export-root">
    <h1 class="h2">Экспорт данных</h1>
    <p class="text-muted">Выгрузка данных о табелях</p>
    <div class="form-group mb-3">
      <label class="form-label">Куда выгружать</label>
      <select class="form-select form-select-sm">
        <option v-for="item in exportTypes" :value="item.value">{{ item.label }}</option>
      </select>
    </div>
    <div class="form-group p-3 border rounded-3">
      <label class="form-label">Страницы</label>
      <p class="small text-muted mb-2">Какие страницы выгружать</p>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-check">
            <input v-model="pageType" value="all" class="form-check-input" name="export_page" type="radio"
                   id="id_page_all">
            <label class="form-check-label" for="id_page_all">
              Все страницы
            </label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-check">
            <input v-model="pageType"
                   value="custom"
                   class="form-check-input"
                   name="export_page"
                   type="radio"
                   id="id_page_custom">
            <label class="form-check-label text-nowrap d-block" for="id_page_custom">
              Произвольный номер
            </label>
          </div>
        </div>
      </div>
      <div v-if="showCustomPage" class="mt-2">
        <InputNumber id="id_page_number" v-model="page" class="w-100" placeholder="Введите номер страницы"/>
      </div>
    </div>
    <div class="mt-3 ms-auto float-end">
      <RButton @click="download" text="Экспортировать"/>
      <RButton @click="closeModal" design="danger" class="ms-2" text="Отменить"/>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import RButton from "@/application/UIKit/RButton.vue";
import {APIManager} from "@/common/helpers/api/manager";
import {responseExtractFilename} from "@/common/helpers/api/utils";

export default {
  name: "ExportRoot",
  components: {
    RButton,
    InputText,
    InputNumber
  },
  data() {
    return {
      dateFrom: new URLSearchParams(window.location.search).get("date_from"),
      dateTo: new URLSearchParams(window.location.search).get("date_to"),
      searchUser: new URLSearchParams(window.location.search).get("creator"),
      searchBoard: new URLSearchParams(window.location.search).get("board"),
      searchTask: new URLSearchParams(window.location.search).get("title"),
      page: new URLSearchParams(window.location.search).get("page"),

      exportTypes: [{label: "Excel", value: "excel"}],
      exportType: "excel",

      pageType: "all",
      currentPage: new URLSearchParams(window.location.search).get("page"),
    }
  },
  methods: {
    async download() {
      const data = {
        board: this.searchBoard,
        title: this.searchTask,
        date_from: this.formatDate(this.dateFrom),
        date_to: this.formatDate(this.dateTo),
        creator: this.searchUser,
        page: this.page,
        page_type: this.pageType
      }
      const response = await APIManager.reportDownload(data)

      const filename = responseExtractFilename(response)
      let blob = new Blob([response.data], { type: response.data.type })
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
      link.remove()

      this.$store.commit("addToast", {"text": "Экспорт завершен"})
      this.closeModal()
    },
    closeModal() {
      this.$emit("modalClose")
    },
  },
  computed: {
    showCustomPage() {
      return this.pageType === "custom"
    }
  }
}
</script>

<style scoped>
@media (min-width: 550px) {
  .export-root .form-group {
    min-width: 500px;
  }
}
</style>
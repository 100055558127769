<template>
  <div>
    <top-bar/>
    <side-bar @toggleDeleteBoard="onToggleBoardDelete"
              @toggleArchiveBoard="onToggleBoardArchive"
              @toggleCreateBoardModal="showCreateBoardModal=true"/>
    <div class="layout-content">
      <div class="app" :class="{'is-wide': $store.getters.SIDEBAR_ACTIVE}">
        <router-view/>
      </div>
    </div>
    <custom-modal @modalClose="closeCreateBoard" :show-modal-prop="showCreateBoardModal">
      <create-board-window @modalClose="closeCreateBoard"></create-board-window>
    </custom-modal>
    <custom-modal @modalClose="showDeleteBoardModal=false" :show-modal-prop="showDeleteBoardModal">
      <board-delete-view :callback="this.deleteCallback" @modalClose="showDeleteBoardModal=false"
                         :board-id="boardToDelete"/>
    </custom-modal>
    <custom-modal @modalClose="showArchiveBoardModal=false" :show-modal-prop="showArchiveBoardModal">
      <destroy-confirmation-modal
          @itemDelete="archiveCallback"
          :item_id="boardToArchive"
          @modalClose="showArchiveBoardModal=false"
          delete-text="В архив"
          header-text="Вы уверены что хотите архивировать проект?"
          muted-text="Данные будут перемещены в архив"/>
    </custom-modal>
  </div>
</template>

<script>
import TopBar from "@/application/components/UIComponents/TopBar";
import SideBar from "@/application/components/UIComponents/SideBar";
import CreateBoardWindow from "@/application/components/ViewComponents/BoardComponents/CreateBoardWindow";
import CustomModal from "@/application/components/UIComponents/CustomModal";
import DestroyConfirmationModal from "@/application/components/UIComponents/DestroyConfirmationModal";
import BoardDeleteView from "@/application/components/ViewComponents/BoardComponents/BoardDeleteView";
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "LayoutComponent",
  components: {BoardDeleteView, CustomModal, CreateBoardWindow, SideBar, TopBar, DestroyConfirmationModal},
  data() {
    return {
      showCreateBoardModal: false,
      showDeleteBoardModal: false,
      showArchiveBoardModal: false,
      boardToDelete: null,
      boardToArchive: null,

    }
  },
  methods: {
    closeCreateBoard() {
      this.showCreateBoardModal = false
      this.$store.commit("closeSidebar")
    },
    onToggleBoardDelete(boardId) {
      this.boardToDelete = boardId
      this.showDeleteBoardModal = true
    },
    deleteCallback() {
      this.showDeleteBoardModal = false
      this.boardToDelete = null
      this.$store.commit("closeSidebar")
      this.$router.push({name: 'main'})
    },
    onToggleBoardArchive(boardId) {
      this.boardToArchive = boardId
      this.showArchiveBoardModal = true
    },
    archiveCallback(boardId) {
      APIManager.update({model: 'board', id: boardId, data: {archive: true}}).then(res => {
        this.$store.state.boardList = this.$store.state.boardList.filter(b => b.id !== boardId)
        this.showArchiveBoardModal = false
        this.boardToArchive = null
        this.$store.commit("closeSidebar")
        if (this.$route.name === 'boardDetail' && this.$route.params.id === boardId) {
          this.$router.push({name: 'main'})
        }
      })
    }
  }
}
</script>

<style scoped>
.layout-content {
  padding: 15px;
  height: calc(100svh - 60px);
  background-color: #eaecf1;
}

.app {
  transition: .2s;
}

@media (max-width: 767px) {
  .layout-content {
    overflow: auto;
  }
}
</style>
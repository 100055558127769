<template>
    
</template>

<script>
    export default {
        name: "VueTitle",
        props: ['title'],
        watch: {
            title: {
                immediate: true,
                handler() {
                    document.title = this.title + ' | Task Ease';
                }
            }
        },
        render () {
        },
    }
</script>

<style scoped>

</style>
<template>
  <div class="planned-hours">
    <div v-if="isEdit">
      <form class="d-flex align-items-center" @submit.prevent="onSubmit">
        <div class="me-2">
          <input ref="hours"
                 :value="hours"
                 contenteditable="true"
                 class="planned-input"
                 title="Запланировано часов"
                 type="number">
          <p class="m-0 ms-1 d-inline-block">ч.</p>
        </div>
        <div class="me-1">
          <input ref="minutes"
                 @change="onChange"
                 :value="minutes"
                 contenteditable="true"
                 class="planned-input"
                 title="Запланировано минут"
                 type="number">
          <p class="m-0 ms-1 d-inline-block">м.</p>
        </div>
        <div>
          <button type="submit" class="btn btn-sm accept-btn very-small text-success">
            <font-awesome-icon icon="fas fa-check"/>
          </button>
          <button type="button" @click="cancel" class="btn btn-sm accept-btn very-small text-danger">
            <font-awesome-icon icon="fas fa-times"/>
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <div @click="isEdit=true" class="clickable">
        <span>{{ scheduledText }}</span>
        <font-awesome-icon class="ms-2 text-muted" icon="fa-solid fa-clock"/>
      </div>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "PlannedHours",
  components: {FontAwesomeIcon},
  props: {
    taskId: String,
    hours: Number,
    minutes: Number,
  },
  data() {
    return {
      isEdit: false,
      minutesMax: 59
    }
  },
  methods: {
    cancel() {
      this.$refs.hours.value = '0'
      this.$refs.hours.minutes = '0'
      this.isEdit = false
    },
    onChange($event) {
      const value = parseInt($event.target.value)
      if (value > this.minutesMax) {
        $event.target.value = this.minutesMax
      }
    },
    onSubmit() {
      const data = {
        scheduled_hours: this.$refs.hours.value,
        scheduled_minutes: this.$refs.minutes.value,
      }
      this.$emit('taskUpdate', data)
      this.isEdit = false
    },
  },
  computed: {
    scheduledText() {
      if (!this.hours && !this.minutes) {
        return 'Время не запланировано'
      }
      let text = ''
      if (this.hours) {
        text += `${this.hours} часов`
      }
      if (this.minutes) {
        if (text.length) {
          text += ' '
        }
        text += `${this.minutes} минут`
      }
      return text
    }
  }
}
</script>

<style scoped>
.accept-btn {
  box-shadow: none!important;
}
.accept-btn:hover {
  background-color: #efefef;
}

.planned-input {
  width: 60px;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  text-align: end;
}

.planned-input:hover {
  cursor: pointer;
}

.planned-input:focus {
  outline: none;
  cursor: text;
}
</style>
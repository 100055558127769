import Main from "@/application/components/ViewComponents/Main";
import {createRouter} from "vue-router";
import {createWebHistory} from "vue-router";
import BoardView from "@/application/components/ViewComponents/BoardComponents/BoardView";
import TaskWindow from "@/application/components/ViewComponents/TaskComponents/TaskWindow";
import LoginView from "@/application/components/ViewComponents/AuthComponents/LoginView";
import store from "@/store"
import ProfileView from "@/application/components/ViewComponents/ProfileView";
import NotFound from "@/application/components/ViewComponents/NotFound";
import LayoutComponent from "@/application/components/LayoutComponents/LayoutComponent";
import BoardSettings from "@/application/components/ViewComponents/BoardComponents/BoardSettings";
import StageSettings from "@/application/components/ViewComponents/StageComponents/StageSettings";
import RegisterView from "@/application/components/ViewComponents/AuthComponents/RegisterView";
import ConfirmationVIew from "@/application/components/ViewComponents/AuthComponents/ConfirmationVIew";
import TaskDeleteView from "@/application/components/ViewComponents/TaskComponents/TaskDeleteView";
import ReportCreate from "@/application/components/ViewComponents/ReportComponents/ReportCreate";
import StageDeleteView from "@/application/components/ViewComponents/StageComponents/StageDeleteView";
import BoardDeleteView from "@/application/components/ViewComponents/BoardComponents/BoardDeleteView";
import ReportList from "@/application/components/ViewComponents/ReportComponents/ReportList";
import ExportRoot from "@/application/components/Export/ExportRoot.vue";

const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: Main,
        name: 'main'
      },
      {
        path: '/reports',
        component: ReportList,
        name: 'reportList',
        children: [
          {
            path: '/report/export',
            component: ExportRoot,
            props: true,
            name: 'reportExport',
            meta: {
              showModal: true
            }
          },
        ]
      },
      {
        path: '/board/:id/task/:taskId',
        component: TaskWindow,
        name: 'taskDetail',
        props: true,
        children: [
          {
            path: '/board/:id/task/:taskId/report/create',
            component: ReportCreate,
            props: true,
            name: 'reportCreate',
            meta: {
              showModal: true
            }
          },
          {
            path: '/board/:id/task/:taskId/report/:repId',
            component: ReportCreate,
            props: true,
            name: 'reportDetail',
            meta: {
              showModal: true
            }
          }
        ]
      },
      {
        path: '/board/:id',
        component: BoardView,
        name: 'boardDetail',
        children: [
          {
            path: '/board/:id/stage/settings/:stageId',
            component: StageSettings,
            props: true,
            meta: {
              showModal: true
            },
            name: 'stageSettings'
          },
          {
            path: '/board/:id/settings',
            component: BoardSettings,
            props: true,
            name: 'boardSettings',
            meta: {
              showModal: true,
              force: true
            },
          },
          {
            path: '/board/:id/delete',
            component: BoardDeleteView,
            props: true,
            name: 'boardDelete',
            meta: {
              showModal: true,
              force: true
            },
          },
          {
            path: '/board/:id/task/:taskId/delete',
            component: TaskDeleteView,
            props: true,
            name: 'taskDelete',
            meta: {
              showModal: true
            },
          },
          {
            path: '/board/:id/stage/:stageId/delete',
            component: StageDeleteView,
            props: true,
            name: 'stageDelete',
            meta: {
              showModal: true
            },
          }
        ]
      },
      {
        path: '/profile/:id?',
        component: ProfileView,
        name: 'ProfileView'
      },
    ]
  },
  {
    path: '/auth/login',
    component: LoginView,
    name: 'login',
    meta: {
      hideView: false,
      allowAny: true
    }
  },
  {
    path: '/auth/register',
    component: RegisterView,
    name: 'register',
    meta: {
      hideView: false,
      allowAny: true
    }
  },
  {
    path: '/auth/confirm',
    component: ConfirmationVIew,
    name: 'authConfirm',
    meta: {
      hideView: false,
      allowAny: true
    }
  },


  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'notFound'
  }

]

const router = createRouter(
  {
    routes,
    history: createWebHistory()
  }
)

router.beforeEach((to, from, next) => {
  store.commit("setPrevRoute", from.name)
  store.commit("setRoute", to.name)
  if (store.getters.IS_AUTH && to.name === 'login') {
    next('/')
    return;
  }
  if (!to.matched.some(record => record.meta.allowAny)) {
    if (store.getters.IS_AUTH) {
      next()
      return;
    }
    next('/auth/login')
  } else {
    next()
  }

})

export default router;

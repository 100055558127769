<script>
import UserAvatarList from "@/application/UIKit/UserAvatarList.vue";
import {formatCurrency} from "@/common/helpers/api/utils";

export default {
  name: "DashboardTableRow",
  components: {UserAvatarList},
  props: {
    project: {
      required: true
    }
  },
  computed: {
    realBudget() {
      return formatCurrency(this.project.real_budget)
    },
    budget() {
      return formatCurrency(this.project.budget)
    }
  }
}
</script>

<template>
  <tr>
    <td>
      <router-link :to="{name: 'boardDetail', params: {id: project.id}}">{{ project.title }}</router-link>
    </td>
    <td>
      <UserAvatarList :user-list="project.members"/>
    </td>
    <td>{{ project.created_at }}</td>
    <td>{{ budget }}</td>
    <td>{{ realBudget }}</td>
    <td>{{ project.total_tasks }}/{{ project.completed_tasks }}</td>
  </tr>
</template>

<template>
  <div class="d-flex align-items-center">
    <div>
      <div>
        <input :checked="item.is_done" @change="setCompleted" class="form-check-input me-2" type="checkbox" value=""
               :id="'id_checkbox_'+item.id">
        <label class="form-check-label task-check small"
               :class="{'text-decoration-line-through': item.is_done}"
               :for="'id_checkbox_'+item.id">
          {{ item.text }}
        </label>
      </div>
    </div>
    <div class="ms-3">
      <button @click="delCheck" class="btn btn-sm check-del-btn very-small text-danger ms-auto">
        <font-awesome-icon icon="fas fa-trash"/>
      </button>
    </div>
  </div>
</template>

<script>
import {APIManager} from "@/common/helpers/api/manager";

export default {
  name: "CheckboxItem",
  props: ['item'],
  methods: {
    delCheck() {
      APIManager.destroy(
          {model: 'checkbox', id: this.item.id}
      ).then(res => this.$emit('deleteCheck', this.item.id))
    },
    setCompleted() {
      APIManager.update(
          {model: 'checkbox', id: this.item.id, data: {is_done: !this.item.is_done}}
      ).then(res => {
        this.$emit('update', res)
      })
    }
  }
}
</script>

<style scoped>
.task-check:hover {
  cursor: pointer;
}

.check-del-btn {
  box-shadow: none !important;
}

.check-del-btn:hover {
  color: darkred !important;
}
</style>
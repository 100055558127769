<template>
  <li :class="{'active-link': activeLink}" @contextmenu="openContextMenu">
    <ContextMenu ref="contextMenu" :model="menuItems"/>
    <router-link :to="link"
                 @click="menuClick"
                 class="text-white main-nav-link d-flex align-items-center">
      <span class="main-link-text item-small ps-0">{{ board.title }}</span>
    </router-link>
  </li>
</template>

<script>
import ContextMenu from "primevue/contextmenu";

export default {
  name: "SideBarMenuItem",
  components: {
    ContextMenu
  },
  props: {
    board: {
      type: Object,
      default: {}
    },
    showContext: Boolean
  },
  methods: {
    menuClick() {
      this.$store.commit("closeSidebar")
    },
    hideCallback($event) {
      this.$nextTick(() => {
        const contextMenu = this.$refs.contextMenu
        if (contextMenu) {
          contextMenu.hide($event)
        }
      })
    },
    showCallback($event) {
      this.$nextTick(() => {
        const contextMenu = this.$refs.contextMenu
        if (contextMenu) {
          contextMenu.show($event)
        }
      })
    },
    openContextMenu($event) {
      if (!this.showContext) {
        return
      }
      this.$emit("context", $event, this.board.id)
    },
    leaveBoard(i) {
      this.$emit('leaveBoard', this.board.id, this.$store.state.user.id)
    },
    showArchive(i) {
      this.$emit('toggleArchiveBoard', this.board.id)
    },
    showDelete(i) {
      this.$emit('toggleDeleteBoard', this.board.id)
    },
  },
  created() {
    this.$emit("menuLoaded", this.board.id, {hide: this.hideCallback, show: this.showCallback})
  },
  computed: {
    activeLink() {
      return this.$store.state.route === "boardDetail" && this.$route.params.id === this.board.id
    },
    link() {
      return {name: "boardDetail", params: {id: this.board.id}}
    },
    menuItems() {
      const userId = this.$store.state.user.id
      const menuItems = [
        {
          id: 1,
          label: 'Архивировать',
          icon: 'pi pi-box',
          command: this.showArchive
        },
        {
          id: 2,
          label: 'Удалить',
          icon: 'pi pi-trash',
          command: this.showDelete
        },
      ]
      if (userId !== this.board.creatorId) {
        menuItems.push({
          id: 3,
          label: 'Покинуть проект',
          icon: 'pi pi-user-minus',
          command: this.leaveBoard
        })
      }
      return menuItems
    }
  }
}
</script>

<style scoped>
</style>